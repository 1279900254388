.underline_recenzare{
    width:20%;
    display:inline-block;
    text-align:center;
    vertical-align:middle;
    border-bottom:2px solid #1dbc60;
    margin-left:2%;
    margin-right:2%;  
}
.underline_container_redactie{
    display:flex;
    /* flex-direction:column; */
    width:30%;
    margin:auto;
    text-align:center;
    margin-bottom:30px;
}
.separator{
    margin:0 auto;
    width:70%;
    margin-bottom:30px;
    /* margin-top:-20px; */
    /* border:1px solid red; */
    margin:0;
}
.separator span{
    vertical-align:middle;
}
.separator h2{
    margin-bottom:-10px;
}
.myicon{
    /* border:1px solid red; */
    margin:0;
}
.paragraf_colegiu h3{
    border: 2px outset #1dbc60;
    display:inline-block;
    padding:5px 10px;
    background:#1dbc60;
    border-radius:5px;
    color:#fbfbfb;
}
.separator_colegiu{
    width:100%;
}


/* =================media queries================= */
/* =================media queries================= */
/* =================media queries================= */
/* =================media queries================= */
/* =================media queries================= */
/* =================media queries================= */
/* =================media queries================= */


