.admin_buttons a {
    color:#1dbc60;
    font-weight:bold;
    border:1px solid #cdcdcd;
    margin:20px;
    /* margin-top:100px; */
    display:inline-block;
    padding:20px;
    background:white;
}
.admin_buttons a:hover{
    background:#1dbc60;
    color:white;
}


.meniu_admin{
    height:100px;
    display:flex;
    width:100%;
    margin:auto;
    margin-top:100px;
    /* background:white; */
}
.meniu_admin_subdiv{
    display:flex;
    margin:auto;
}


