.editare_articol_container{
    display:flex;
    flex-direction:column;
    max-height:auto;
    border:1px solid #cdcdcd;
    border-radius:5px;
    background:white;
    gap:50px;
    padding:70px;
    width:60vw;
    margin:auto;
    position:relative;
}
.editare_articol_container>div{
    display:flex;
}
.editare_articol_container >div>input:focus,textarea:focus{
    border-radius:0;
    display: inline-block;
    box-shadow: 0 0 0 1px #1dbc60;
    border-radius: 5px;
    background:#f4fdf4;
}




.editare_articol_container >div>input, textarea{
    outline:none;
    border:1px solid #1dbc60;
    color:grey;
    border-radius:5px;
    width:20vw;
    color:grey;
    text-align:center;
    font-size:16px;
    background:#fbfbfb;
}

.editare_articol_container >div>label{
    color:grey;
    font-weight:bold;
    width:10vw;
}

.editare_articol_container>div>input,textarea{
    /* margin-right:20vw; */
    /* margin-left:5vw; */
}

.editare_articol_container >div>label{
    font-size:20px;
    vertical-align: middle;
    padding:0;
    display:flex;
    /* margin-top:auto;
    margin-bottom:auto; */
}

.editare_submit{
    border:1px solid #1dbc60;
    background:#1dbc60;
    padding:8px 15px;
    font-size:30px;
    border-radius:5px;
    color:white;
    width:20vw;
    margin-left:10vw;
}

.editare_submit:hover{
    background:white;
    color:#1dbc60;
    cursor:pointer;
}

.mesaj_confirmare{
    width:30vw;
    margin:auto;
    font-size:20px;
    display:flex;
    gap:30px;
}
.mesaj_confirmare p{
    margin-top:auto;
    margin-bottom:auto;
}

.criteriu_de_cautare{
    margin-right:0;
    margin-left:auto;
}


.adaugare_autor_nou{
    display:flex;
    flex-direction:column;
    max-height:300px;
    gap:10px;
    padding:5px;
    width:100%;
    margin:auto;
    position:relative;
    margin-top:100px;
}

.adaugare_autor_nou_input{
    outline:none;
    border:1px solid #1dbc60;
    color:grey;
    border-radius:5px;
    width:20vw;
    text-align:center;
    font-size:16px;
    background:#fbfbfb;
}
.adaugare_autor_nou_input:focus{
    display: inline-block;
    box-shadow: 0 0 0 1px #1dbc60;
    border-radius: 5px;
    background:#f4fdf4;
}
.submit_autor_nou{
    width:200px;
    margin-right:20px;
    background:#1dbc60;
    color:white;
    padding:5px 10px;
    font-size:20px;
    border:none;
    border-radius: 5px;
    border:1px solid #1dbc60;
}

.submit_autor_nou:hover{
    color:#1dbc60;
    background:white;
    cursor:pointer;
    border:1px solid #1dbc60;
}

.autor_nou_buton_si_input{
    display:flex;
    width:100%;
    margin:0 auto;
    margin-top:10px;
}

.submit_raspuns{
    background:#1dbc60;
    color:white;
    border:1px solid #1dbc60;
    border-radius:5px;
    cursor:pointer;
    padding:5px 10px;
    margin:auto;
}

.submit_raspuns:hover{
    background:white;
    color:#1dbc60;
}

.titlu_mare_editare_articol{
    margin:20px auto;
    width:20%;
    text-align:center;
}

.submit_text_articol_div{
    margin-top:100px;
    width:100%;
    display:flex;
    flex-direction:column;
}
.text_articol_div{
    display:flex;
}
#text_articol_textarea{
    width:80%;
    padding:10px;
}

.text_articol_label{
    font-size:20px !important;
    font-weight:bold;
    color:grey;
    margin-top:auto;
    margin-bottom:auto;
    margin-right:20px;
}

.submit_text_articol_button{
    margin-top:20px;
    font-size:20px;
    padding:5px 10px;
}