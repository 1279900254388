.container{
    width:99%;
    margin:0 auto;
    overflow: hidden;
}
.homepage_div{
    display:flex;
    justify-content:space-around;
    margin:0 auto;
    height:auto;
}
.homepage_subdiv_left{
    width:24%;
    margin-top:0;
    overflow:hidden;
    animation: 1.1s ease-in-out 0s 1 bounce_left;
    animation-delay:500ms;
    animation-fill-mode:both;
    height:auto;
    margin-left:-27%;
}
.homepage_subdiv_right{
    width:24%;
    overflow:hidden;
    margin-top:0;
    height:auto;
    margin-right:-27%;
    animation: 1.1s ease-out 0s 1 bounce_right;
    animation-delay:500ms;
    animation-fill-mode:both;
}
.homepage_subdiv_center{
    width:49%;
    overflow:hidden;
    height:auto;
    animation: 1.1s ease-out 0s 1 bounce_top;
    animation-delay:500ms;
    animation-fill-mode:both;
    position:absolute;
    z-index:1000;
    overflow:visible;
}
.overlay {
    position:absolute;
    bottom:0;
    top:0;
    left:0;
    right:0;
    overflow:hidden;
    background-color:rgba(0,0,0,0.25);
    cursor:pointer;
}
.overlay_left p{
    opacity:1;
    color:white;
    font-size: 26px;
    font-weight:600;
    margin: auto;
    /* text-align:center; */
}
.overlay_right p{
    opacity:1;
    color:white;
    font-size: 26px;
    font-weight:600;
    margin: auto;
    /* text-align:center; */
}
.overlay_right{
    position:absolute;
    bottom:0;
    top:0;
    left:0;
    right:0;
    min-width:100%;
    max-width:100%;
    overflow:hidden;
    display:flex;
    background-color:rgba(0,0,0,0.25);
    cursor:pointer;
}
.overlay_left{
    position:absolute;
    bottom:0;
    top:0;
    left:0;
    right:0;
    min-width:100%;
    max-width:100%;
    overflow:hidden;
    display:flex;
    background-color:rgba(0,0,0,0.25);
    cursor:pointer;
}
@keyframes bounce_top {
    0%{transform: translateY(-100%);} 
    40% {transform: translateY(5%);}
    60% {transform: translateY(-5%);} 
    80%{transform:translateY(+2%)}
}  
@keyframes bounce_left {
    0%{transform: translateX(-100%);} 
    40% {transform: translateX(5%);}
    60% {transform: translateX(-5%);} 
    80% {transform: translateX(2%);} 
} 
@keyframes bounce_right {
    0%{transform: translateX(+100%);} 
    40% {transform: translateX(-5%);}
    60% {transform: translateX(+5%);} 
    80% {transform: translateX(-2%);} 
} 
.image{
    width:100%;
    height:100%;
    object-fit:contain;
    transition: transform 1s ease;
}
.image_div{
    margin-bottom:2%;
    overflow:hidden;
    position:relative;
    display:flex;    
    justify-content: center;
    align-items: center;
    max-width:100%; 
}
.image_div:hover img{
    transform: scale(1.3);
    overflow:hidden;
}
.center_image_div{
    margin-top:0;
    overflow:hidden;
    width:100%;
    height:auto;
    max-width:100%; 
    animation:1.1s ease-out 0s 1 bounce_top; 
    animation-delay:500ms;
    animation-fill-mode:both;
}
.centru{
    display:block;
    text-align:left;
}
.rand1{
    font-size:9vmin;
    text-align:center;
    font-weight:bold;
}
.rand2{
    font-size:5vmin;
    padding-bottom:120px;
    text-align:center;
    margin-top:-80px;
    font-weight:bold;
    width:100%;
}
.rand3{
    font-size:3vmin;
    text-align:center;
    font-weight:bold;
    line-height:0px;
}
.rand4{
    font-size:3vmin;
    text-align:center;
    font-weight:bold;
    line-height:80px;
}
.rand5{
    font-size:3vmin;
    text-align:center;
    font-weight:bold;
    line-height:100px;
}
.center_title_div{
    width:50%;
    text-align:center;
    margin-left:10px;
}
.overlay_center{
    position:absolute;
    bottom:0;
    top:0;
    left:0;
    right:0;
    min-width:100%;
    max-width:100%;
    overflow:hidden;
    display:flex;
    background-color:rgba(0,0,0,0.1);
    cursor:pointer;
    color:white;
}
.overlay_left p, .overlay_center p, .overlay_right p{
    font-size:1.2vw;
}
body{
    border-top:14px solid black;
}

/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/

@media only screen and (min-width:100px) and (max-width:767px){
    .overlay_left p, .overlay_center p, .overlay_right p{
        font-size:4vw;
    }
    #root{
        padding-bottom:400px;
    }
    .container .homepage_div{
        width:96%;
        margin:5px auto;
        margin-top:0;
        height:auto;
        margin-bottom:5vh;
        height:auto;
        display:flex;
        flex-direction:column;
        overflow:hidden;
    }
    #image1{
        margin-top:0;
    }
    .homepage_subdiv_left, .homepage_subdiv_center, .homepage_subdiv_right{
        width:100%;
        display:flex;
        flex-direction:column;
        margin: 0 auto;
        overflow:hidden;
        animation:none;
        position:relative;
        animation:1.1s ease-out 0s 1 bounce_left; 
        animation-delay:500ms;
        animation-fill-mode:both;
    }
    .image_div, .center_image_div{
        width:100%;
        margin:1% auto;
        animation:none;
        animation:1.1s ease-out 0s 1 bounce_left; 
        animation-delay:500ms;
    }
    .image{
        width:100%;
    }
    .overlay_left p, .overlay_center p, .overlay_right p{
        font-size:5vw !important;
    }
    .container, .homepage_div{
        width:96%;
    }
    #image1{
        margin-top:0;
    }
}

/* ==============Landscape mode================== */
/* ==============Landscape mode================== */
/* ==============Landscape mode================== */
/* ==============Landscape mode================== */
/* ==============Landscape mode================== */
/* ==============Landscape mode================== */

@media only screen and (min-width:100px) and (max-width:767px) and (orientation:landscape){
    .overlay_left p, .overlay_center p, .overlay_right p{
        font-size:4vw;
    }
    #root{
        padding-bottom:400px;
    }
    .container .homepage_div{
        width:96%;
        margin:5px auto;
        margin-top:0;
        height:auto;
        margin-bottom:5vh;
        height:auto;
        display:flex;
        flex-direction:column;
        overflow:hidden;
    }
    #image1{
        margin-top:0;
    }
    .homepage_subdiv_left, .homepage_subdiv_center, .homepage_subdiv_right{
        width:100%;
        display:flex;
        flex-direction:column;
        margin: 0 auto;
        overflow:hidden;
        animation:none;
        position:relative;
        animation:1.1s ease-out 0s 1 bounce_left; 
        animation-delay:500ms;
        animation-fill-mode:both;
    }
    .image_div, .center_image_div{
        width:100%;
        margin:1% auto;
        animation:none;
        animation:1.1s ease-out 0s 1 bounce_left; 
        animation-delay:500ms;
    }
    .image{
        width:100%;
    }
    .overlay_left p, .overlay_center p, .overlay_right p{
        font-size:5vw !important;
    }
    .container, .homepage_div{
        width:96%;
    }
    #image1{
        margin-top:0;
    }
}

/*320, 481, 641, 961, 1025, 1281*/




