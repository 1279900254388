@import '~antd/dist/antd.css';

.footer{
    color:white;
    background:black;
    left:0;
    right:0;
    display:flex;
    height:600px;
    flex-direction:column;
    position: absolute;
    bottom:0;
    font-size:0.8vw;
    overflow:hidden !important;
}
.footer h3{
    color:white;
}
.footer_links{
    width:80%;
    height:80%;
    background:black;
    display:flex;
    margin:auto;
}
.footer_links_inner_div{
    margin: auto;
    display:flex;
    justify-content: space-around;
    width:80%;
    margin:auto;
}
.copyright{
    background:#444444;
    width:100%;
    height:5vh;
    display:flex;
    font-size:0.9vw;
}
.copyright_text{
    margin:auto;
}
.despre{
    width:100%;
}
.linkuri_utile{
    display:flex;
    flex-direction:column;
    margin:0% 3%;
    margin-top:1.5vh !important;
    margin-left:5%;
}
.linkuri_utile h3{
    margin-bottom: 3vh;
}
.linkuri_utile a{
    color:white;
    text-decoration:none;
}
.informatii_contact{
    display:flex;
    flex-direction:column;
    margin:0% 3%;
    margin-top:1.5vh !important;
}
.informatii_contact a {
    text-decoration: none;
    color:white;
}
.informatii_contact h3{
    margin-bottom:3vh;
}
.facebook_like{
    margin-top:1.5vh !important;
    margin-right:0 !important;
}
.facebook_like h3{
    margin-bottom:3vh;
}
.facebook_like a{
    color:white;
}
.arrow{
    color:#1dbc60;
    font-size:0.8vw;
    display:inline;
    margin-right:6px;
}
.logo_footer img{
    width:60px;
    margin-left:6px;
}
.logo_footer{
    margin:auto;
}
.despre p{
    margin:0;
    padding:0;
}
.logo_si_despre{
    width:26%;
    display:flex;
    flex-direction:column;
    margin-left:0;
}

/* ==============media queries===================== */
/* ==============media queries===================== */
/* ==============media queries===================== */
/* ==============media queries===================== */
/* ==============media queries===================== */
/* ==============media queries===================== */
/* ==============media queries===================== */

@media only screen and (min-width:100px) and (max-width:767px){
    .footer{
        width:100%;
        display:flex;
        flex-direction:column;
        margin: 0 auto !important;
        height:28rem;
        overflow:hidden;
        font-size:4vw;
    }
    .footer_links{
        width:90vw;
        height:auto;
    }
    .footer_links_inner_div{
        width:100vw;
        display:flex;
        flex-direction:column;
        justify-content:center !important;
        justify-content:space-between !important;
        gap:1.5vh;
    }
    .link_util{
        font-size:6px;
        display:flex;
        vertical-align:middle;
        
    }
    .arrow{
        font-size:6px;
        vertical-align: middle;
        display:flex;
        margin-top:auto;
        margin-bottom:auto;
        margin-right:10px;
    }
    .logo_si_despre{
        width:100%;
        font-size:6px;
    }
    .logo_footer{
        margin-left:-6px;
        width:20vw;
    }
    .logo_footer img{
        width:20vw;
    }
    .linkuri_utile{
        width:100%;
        margin: auto;
    }
    .linkuri_utile h3{
        margin-bottom:2px;
        font-size:8px;
    }
    .informatii_contact{
        width:100%;
        margin:auto;
    }
    .informatii_contact h3{
        margin-bottom:2px;
        font-size:8px;
    }
    .facebook_like{
        width:100%;
        margin:auto;
    }
    .facebook_like h3{
        margin-bottom: 2px;
        font-size:8px;
    }
    .footer_link_text{
        margin-left:1.3vw;
    }
    .footer_link_email{
        margin-left:0.3vw;
    }
    .copyright{
        height:30px;
    }
    .copyright_text{
        font-size:4vw;
        font-size:8px;
    }
    #root{
        padding-bottom:500px;
    }
}

@media only screen and (min-width:150px) and (max-width:767px){
    .footer{
        height:25rem;
    }
}

@media only screen and (min-width:200px) and (max-width:767px){
    .footer{
        height:30rem;
    }
    .logo_si_despre{
        font-size:8px;
    }
    .linkuri_utile h3{
        font-size:10px;
    }
    .informatii_contact h3{
        font-size:10px;
    }
    .facebook_like h3{
        font-size:10px;
    }
    .arrow{
        font-size:8px;
    }
    .link_util{
        font-size:8px;
    }
}

@media only screen and (min-width:250px) and (max-width:767px){
    .footer_link_text{
        margin-left:0.7vw;
    }
}

@media only screen and (min-width:300px) and (max-width:767px){
    .footer{
        height:32rem;
    }
    .logo_si_despre{
        font-size:10px;
    }
    .linkuri_utile h3{
        font-size:12px;
    }
    .informatii_contact h3{
        font-size:12px;
    }
    .facebook_like h3{
        font-size:12px;
    }    
    .arrow{
        font-size:10px;
    }
    .link_util{
        font-size:10px;
    }
    .copyright_text{
        font-size:12px;
    }
    .copyright{
        padding:10px;
        height:50px;
    }
    #root{
        padding-bottom:600px;
    }
    .footer_link_text{
        margin-left:0.5vw;
    }
}

@media only screen and (min-width:350px) and (max-width:767px){
    .footer{
        height:40rem;
    }
    .logo_si_despre{
        font-size:12px;
    }
    .linkuri_utile h3{
        font-size:14px;
    }
    .informatii_contact h3{
        font-size:14px;
    }
    .facebook_like h3{
        font-size:14px;
    }    
    .arrow{
        font-size:12px;
    }
    .link_util{
        font-size:12px;
    }
    .copyright_text{
        font-size:14px;
    }
    .copyright{
        padding:12px;
        height:60px;
    }
    #root{
        padding-bottom:650px;
    }
    .footer_link_text{
        margin-left:0.4vw;
    }
}

@media only screen and (min-width:450px) and (max-width:767px){
    .footer{
        height:48rem;
    }
    .logo_si_despre{
        font-size:14px;
    }
    .linkuri_utile h3{
        font-size:16px;
    }
    .informatii_contact h3{
        font-size:16px;
    }
    .facebook_like h3{
        font-size:16px;
    }    
    .arrow{
        font-size:14px;
    }
    .link_util{
        font-size:14px;
    }
    .copyright_text{
        font-size:16px;
    }
    .copyright{
        padding:14px;
        height:60px;
    }
    #root{
        padding-bottom:800px;
    }
    .footer_link_text{
        margin-left:0.3vw;
    }
}

@media only screen and (min-width:550px) and (max-width:767px){
    .footer{
        height:60rem;
    }
    .logo_si_despre{
        font-size:16px;
        margin-bottom:20px;
    }
    .linkuri_utile h3{
        font-size:18px;
    }
    .informatii_contact h3{
        font-size:18px;
    }
    .facebook_like h3{
        font-size:18px;
    }
    .arrow{
        font-size:16px;
    }
    .link_util{
        font-size:16px;
    }
    .copyright_text{
        font-size:18px;
    }
    .copyright{
        padding:20px;
        height:70px;
    }
    #root{
        padding-bottom:1000px;
    }
    .footer_link_text{
        margin-left:0.2vw;
    }
    .linkuri_utile{
        margin-bottom:20px;
    }
    .informatii_contact{
        margin-bottom:20px;
    }
}

@media only screen and (min-width:550px) and (max-width:767px){
    .footer{
        height:70rem;
    }
    .logo_si_despre{
        font-size:20px;
        margin-bottom:20px;
    }
    
    .linkuri_utile h3{
        font-size:22px;
    }
    .informatii_contact h3{
        font-size:22px;
    }
    .facebook_like h3{
        font-size:22px;
    }
    
    .arrow{
        font-size:20px;
    }
    .link_util{
        font-size:20px;
    }

    .copyright_text{
        font-size:22px;
    }
    .copyright{
        padding:30px;
        height:100px;
    }
    #root{
        padding-bottom:1300px;
    }
    .linkuri_utile{
        margin-bottom:20px;
    }
    .informatii_contact{
        margin-bottom:20px;
    }
}

@media only screen and (min-width:768px) {
    .footer{
        height:30vw !important;
    }
    .link_util{
        display:flex;
    }
    .link_util div{
        margin-top:0;
        padding:0;
    }
    .svg-inline--fa{
        margin-top:auto;
        margin-bottom:auto;
    }
    .copyright{
        height:4vw !important;
    }
    .logo_footer{
        width:50px;
        height:50px;
    }
    .logo_footer img{
        width:50px;
        height:50px;
    }
}

@media only screen and (min-width:1080px){
    .footer{
        height:20vw !important;
    }
    .linkuri_utile h3{ 
        margin-bottom:20px;
    }
    .informatii_contact h3{
        margin-bottom:20px;
    }
    .facebook_like h3{
        margin-bottom:20px;
    }
    .copyright{
        height: 4vw;
    }
    .link_util{
        display:flex;
    }
    .arrow{
        margin-top:auto;
        margin-bottom:auto; 
    }
}

@media only screen and (min-width:2000px){
    #root{
        padding-bottom:800px;
        padding-bottom:30vw;
    }
}

/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */

@media only screen  and (max-width:767px) and (orientation:landscape){
    .footer{
        width:100%;
        display:flex;
        flex-direction:column;
        margin: 0 auto !important;
        height:100rem;
        overflow:hidden;
        font-size:4vw;
    }
    .footer_links{
        width:90vw;
        height:auto;
    }
    .footer_links_inner_div{
        width:100vw;
        display:flex;
        flex-direction:column;
        justify-content:center !important;
        justify-content:space-between !important;
        gap:1.5vh;
    }
    .link_util{
        font-size:24px;
        display:flex;
        vertical-align:middle;
    }
    .arrow{
        font-size:24px;
        vertical-align: middle;
        display:flex;
        margin-top:auto;
        margin-bottom:auto;
        margin-right:10px;
    }
    .logo_si_despre{
        width:100%;
        font-size:24px;
        margin-bottom:20px;
    }
    .logo_footer{
        margin-left:-6px;
        width:20vw;
    }
    .logo_footer img{
        width:20vw;
    }
    .linkuri_utile{
        width:100%;
        margin: auto;
        margin-bottom:20px;
    }
    .linkuri_utile h3{
        margin-bottom:2px;
        font-size:26px;
    }
    .informatii_contact{
        width:100%;
        margin:auto;
        margin-bottom:40px;
    }
    .informatii_contact h3{
        margin-bottom:2px;
        font-size:26px;
    }
    .facebook_like{
        width:100%;
        margin:auto;
        font-size:26px;
    }
    .facebook_like h3{
        margin-bottom: 2px;
        font-size:26px;
    }
    .footer_link_text{
        margin-left:1.3vw;
    }
    .footer_link_email{
        margin-left:0.3vw;
    }
    .copyright{
        padding:50px;
        height:150px;
    }
    .copyright_text{
        font-size:26px;
    }
    #root{
        padding-bottom:1900px;
    }
}

@media only screen and (min-width:768px) and (max-width:1079px) and (orientation:landscape){
    #root{
        padding-bottom:400px;
    }
    .footer{
        height:300px;
        font-size:1.2vw;
    }
    .footer_links{
        width:90%;
        height:80%;
        background:black;
        display:flex;
        margin:auto;
    }
    .footer_links_inner_div{
        margin: auto;
        display:flex;
        width:90%;
        margin:auto;
    }
    .copyright{
        height:10vh;
        font-size:1.2vw;
    }
    .arrow{
        color:#1dbc60;
        font-size:1.2vw;
        display:inline;
    }
    .logo_footer img{
        width:60px;
        margin-left:6px;
    }
    .logo_footer{
        margin:auto;
    }
    .despre p{
        margin:0;
        padding:0;
    }
    .logo_si_despre{
        width:40%;
        display:flex;
        flex-direction:column;
        margin-left:0;
    }
    .link_util{
        display:flex;
    }
    .link_util div{
        margin-top:0;
        padding:0;
    }
    .svg-inline--fa{
        margin-top:auto;
        margin-bottom:auto;
    }
}

@media only screen and (min-width:768px) and (orientation:landscape) {
    .footer{
        height:35vw !important;
    }
    .link_util{
        display:flex;
    }
    .link_util div{
        margin-top:0;
        padding:0;
    }
    .svg-inline--fa{
        margin-top:auto;
        margin-bottom:auto;
    }
    .copyright{
        height:4vw !important;
    }
}

@media only screen and (min-width: 1080px) and (orientation:landscape){
    .footer{
        height:25vw !important;
    }
}