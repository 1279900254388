@import '~antd/dist/antd.css';
    


/* //Pontica 1, 23, 24, 27 */

.ant-menu{
  all: reset;
  /* all:reset;
  all:unset; */
}
.ant-menu-item{
  all:reset;
}
.nav a{
  all:reset; 
}
.nav li{
  all:reset;
} 

.nav a::before{
  all:reset;
}
.menu__logo {
  width: 100px;
  height:100px;
  width:5vw;
  height:5vw;
  float: left;
  background-image:url('../../../../images/logo.png');
  background-size:contain;
  background-position:center;
  background-repeat:no-repeat;
  margin:0;
  padding:0;
  border:none;
  border-radius:100%;
}
.logo_container{
  display:flex;
  width:20vw;
}
.logo_container p{
  font-size:1vw;
  color:black;
  width:270px;
  width: 13vw;
  margin:auto;  
  line-height:24px;
  line-height:1.4vw; 
}
.menu__container .ant-menu-submenu-title {
  padding: 10px 20px;
}
.menu__container .menu_left {
  float: left;
}
.menu__container .menu_right {
  float: right;
}
.menu_drawer .ant-drawer-body {
  padding: 0 !important;
}
.nav ul a{
  border-bottom:0.04rem solid #d3d3d3 ;
  border-top:0.04rem solid #d3d3d3 ;
  border-left:0.04rem solid #d3d3d3 ;
}
.ant-menu-item{
  padding:0 !important;
  border-bottom:none !important;
}
.admin{
  border-right:0.07rem solid #d3d3d3;
}
.contact{
  border-right:0.07rem solid #d3d3d3;
}
.logout{
  border-right:0.07rem solid #d3d3d3;
}
.inregistrare{
  border-right:0.07rem solid #d3d3d3;
}

/******************************************** MENIU.css *********************************************/

.nav li{
  display:block;
  margin:0;
}
.nav a{
  text-decoration:none;
  height:100%;
  text-align:center;
  display:block;
  color:black;
  font-size: 0.8vw;
  line-height:3vw;
}
.nav a:hover{
  color:#1dbc60!important;
  transition: all 0.5s ease-in-out;
}
.nav_links{
  display:flex;
  border-bottom:none !important;
}
.ant-menu{
  /* vertical-align:center; */
  margin-top:auto;
  margin-bottom:auto;
}
.active_link {
  color:#1dbc60!important;
  background:#fbfbfb !important;
  z-index:9999;
}
.menu__mobile-button {
  display: none !important; /* use of important to overwrite ant-btn */
  background: #1dbc60;
}
.center_link{
  display:block;
  z-index:11111;
  width:100%;
  height:100%;
  border:none !important;
}
.nav{
  display: flex;
  margin-bottom:0px;
  font-weight:600;
  list-style-type: none;
  justify-content:space-between;
  vertical-align: center;
  margin: 0 0.5%;
  padding:0;
}
.ant-drawer-wrapper-body{
  background:#fbfbfb;
}
.ant-drawer-header{
  background:#1dbc60;
  width:100%;
  border-radius:0px !important;
}
.ant-drawer-title{
  color:#fbfbfb;
  margin-left:11.5%;
}
.ant-menu-item-selected{
  padding:30px;
  background:#fbfbfb !important;
}
.iconita_drawer{
  vertical-align:middle !important;
  margin:auto;
  color:#1dbc60;
  display:flex;
  font-size:40px;
}
.menu__logo a{
  border-right:none !important;
}

/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */


/* pentru meniul 'drawer' , se aplica doar dimensiunilor sub 768px. De aceea am facut un @media separat pt asta */
@media only screen and (max-width:767px){
  .ant-drawer-content-wrapper{ 
    width:70px !important;
  }
  .menu__mobile-button {
    display: inline-block !important;
  }
  .menu_left,
  .menu_right {
    display: none;
  }
  .menu__container .ant-menu-item,
  .menu__container .ant-menu-submenu-title {
    padding: 1px 20px;
  }
  .ant-menu{
    background:#fbfbfb;
  }
  .ant-drawer-header{
    padding-left:10vw;
  }
  .ant-drawer-title{
    font-size:1rem;
    display:block;
    color:white;
    margin-left:0;
  }
  .ant-drawer-body{
    padding:0 !important;
    margin: 20px auto;
    width:75% ;
  }
  .ant-menu-item{
    font-size:0.23rem !important;
    margin-bottom:5px !important;
    height:0.2vw !important;
    line-height:0.2vw !important;
    padding:6px 3px !important;
    border:1px solid #d3d3d3 !important;
    width:100%;
    background:white !important;
    border-radius:2px;
    margin:auto;
  }
  .ant-menu-item-selected::after{
    display:none !important;
  }
  .ant-menu-item-selected{
    background:#fbfbfb !important;
  }
  .admin{
    border-right:none;
  }
  .contact{
    border-right:none;
  }
  .logout{
    border-right: none;
  } 
}

@media only screen and (min-width:100px) and (max-width:767px) {
  .ant-menu-submenu{
    display:none !important;
  }
  .menu__mobile-button {
    height:auto;
    background:#fbfbfb;
    border:none;
    padding:0 !important;
    vertical-align:middle !important;
    box-shadow:none;
    max-width:15%;
    padding:0 !important;
    /* flex-shrink:1; */
  }
  .menu__mobile-button:hover{
    background:none;
  }
  .drawer{
    z-index:99999;
  }  
  .logo_container p{
    font-size:4.2vw;
    line-height:4.6vw;
    color:black;
    text-align:center;
    margin:auto;
    display:inline-block;
    display:flex;
    vertical-align:middle;
    width:70%;
  }
  .menu__logo{
    margin-left:0;  
    width:18vw;
    height:18vw;
  }
  .logo_container{
    width:90%;
  }
  .ant-menu{
    display:flex;
    flex-direction:column;
    border:none;
  }
  .nav{
    margin: 0 auto;
    justify-content:flex-start !important;
    margin-bottom:0 !important;
    border-top:none;
    display:flex;
    justify-content:space-between !important;
    margin:auto;
    padding-left: 2.7%;
    padding-right:4%;
    margin-right:0;
    border-bottom:2px solid #1dbc60;
    z-index:9999;
    height:auto;
  }
  body{
    border-top:none;
  }
  .iconita_drawer{
    font-size:12vw;
    text-align:center;
    display:flex;
    margin-right:0;
  }
}

@media only screen and (min-width:150px)and (max-width:767px){
  .logo_container p{
    font-size:4.4vw;
    line-height:5vw;
  }
}
@media only screen and (min-width:180px) and (max-width:767px){
  .logo_container p{
    font-size:4.45vw;
    line-height:5.1vw;
  }
}

@media only screen and (min-width:200px) and (max-width:767px) {
  .ant-drawer-content-wrapper{ 
    width:150px !important;
  }
  .ant-menu-item{
    font-size:0.45rem !important;
    margin-bottom:10px !important;
    padding:15px 5px !important;
  }
  .logo_container p{
    font-size:4.48vw;
    line-height:5.1vw;
  }
}

@media only screen and (min-width:320px) and (max-width:767px){
  .ant-drawer-content-wrapper{ 
    width:60vw !important;
  }
  .ant-drawer-header{
    padding-left:12% !important;
  }
  .ant-drawer-title{
    font-size:1.2rem;
  }
  .ant-menu-item{
    font-size:0.7rem !important;
  }
  .logo_container p{
    font-size:4.2vw;
    line-height:5vw;
  }
}

@media only screen and (min-width:480px)and (max-width:767px){
  .ant-menu-item{
    font-size:1rem !important;
    padding:20px 10px !important;
  }
}

@media only screen and (min-width:600px) and (max-width:767px){
  .ant-drawer-content-wrapper{ 
    width:70vw !important;
  }

  .ant-drawer-header{
    padding-left:10vw;
  }
  .ant-drawer-title{
    font-size:2.5rem;
  }
  .ant-drawer-header{
    padding:50px !important;
    padding-left:8vw !important;
  }
  .ant-menu-item{
    height:8vw !important;
    line-height:8vw !important;
    padding:0 !important;
    padding-left:5vw !important;
    font-size:1.2rem !important;
  }
}

@media only screen and (min-width:1080px){
  .logo_container p{
    font-size: 0.95vw !important;
    line-height:1.1vw !important;
  }
  .menu__logo{
    width:5vw;
    height:5vw;
  }
  .nav a{
    padding:0.2vw 0.9vw;
  }
}

/* ================Landscape mode================ */
/* ================Landscape mode================ */
/* ================Landscape mode================ */
/* ================Landscape mode================ */
/* ================Landscape mode================ */
/* ================Landscape mode================ */
/* ================Landscape mode================ */

@media only screen and (min-width:768px) and (max-width:1080px){
  .nav a{
    padding:0.2vw 0.9vw;
  }
}