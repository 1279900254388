*{
    clear:both;
    box-sizing:border-box !important;
    font-family:'Quicksand', sans-serif;
}
#root{
    overflow:visible;
}
body{
    background:#fbfbfb;
}
::selection {
    background: #1dbc60; /* WebKit/Blink Browsers */
}
::-moz-selection {
    background: #1dbc60; /* Gecko Browsers */
}
.bigContainer{
    width:97%;
    margin:0 auto;
    padding-bottom:3.125rem;
    display:flex;
    flex-direction:column;
    /* overflow: auto; */
    
}
.smallContainer{
    display:flex;
    width:98%;
    overflow:visible;
}


.CardsContainer{
    width:98%;
    display:flex;
    flex-direction:column;
    overflow:hidden;
    cursor:auto;
}
.left_column{
    margin-left:auto;
    padding:20px 20px;
    padding-bottom:33px;
    width: 30%;
    align-self:flex-start;
    background-color:white;
    border:1px solid #f1f1f1;
    position: sticky !important;
    top:0;
}
.col{
    display:inline-block;
    width: 103%;
    overflow:hidden;
    display: flex;
    align-items: center;
    margin-left:1% !important;
}

.pagination{
    position:relative;
    display:flex;
    flex-direction:column;
    padding:0;
    overflow:auto;
    text-align:left;
    height:10rem;
    background: #fbfbfb;
    border-radius:5px;
    padding:10px;
  
}

.pagination li{
    display:flex;
    font-size:18px;
    width:90%;
    text-align:left;
    margin:left;
    cursor:pointer;    
}
.pagination li:hover{
    color:#1dbc60;
    cursor:pointer;
}
ul{
    display:flex;
    flex-direction:row;
}
.pagination_container{
    width:100%;
}
.titlu_articol{
    text-decoration:none;
    color:black;
    width:78%;
    display:inline;
}
.titlu_articol:hover{
    color:#1dbc60 !important;
}
.continut_card> a> img{
    width:25rem;
    height:31.25rem;
    margin-bottom:0;
    margin-top: 1rem;
    vertical-align:bottom;
    bottom:0;
}
.coperti{
    width:25rem;
    height:31.25rem;
 
}

.logo{
    width: 6.25rem;
    height: 6.25rem;
    background-image:url('../../../images/logo100.png');
    background-position:center;
    background-repeat:no-repeat;
    margin:0;
    padding:0;
    border:none;  
}







.view_all{
    color: #fbfbfb;
    padding: 5px 8px;
    background:#1dbc60;
    font-size:20px;
    outline:none;
    border:1px solid #1dbc60;
    border-radius:5px;
    font-weight:bold;
    cursor:pointer;
}
.view_all:hover{
    color:#1dbc60;
    background:#fbfbfb;
}
.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}
.checkbox > input {
    height: 40px;
    width: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 5px;
    outline: none;
    transition-duration: 0.3s;
    background-color: #fbfbfb;
    cursor: pointer;
    margin-right:10px;
    margin-top:auto;
    margin-bottom:auto;
}
.checkbox > input:checked {
    background-color: #f3f3f3;
}
.checkbox > input:checked + button::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #1dbc60;
    position: absolute;
    left:0.2rem;
    bottom:-0.25rem;
    zoom:2;
}
.view_all_si_checkbox{
    display:flex;
    vertical-align:middle;

}






.view_all_authors{
    color: #fbfbfb;
    padding: 5px 8px;
    background:#1dbc60;
    font-size:20px;
    outline:none;
    border:1px solid #1dbc60;
    border-radius:5px;
    font-weight:bold;
    cursor:pointer;
}
.view_all_authors:hover{
    color:#1dbc60;
    background:#fbfbfb;
}

#view_all_authors{
    height:40px;
    width:40px;
}


.linie_sub_arhiva{
    width:100%;
    margin-bottom:50px;
    margin-top:10px;
}
.sageti{
    width:0px;
    height:0px;
    position:absolute;
    top:0;
}
.icon_arhiva{
    font-size:40px;
    color:#1dbc60;
    margin-top:35px;
    margin-right:20px;
}
.display_articole_button{
    background:#1dbc60;
    border:none;
    border-radius:5px;
    color:#fbfbfb;
    border: 2px outset #1dbc60;
    outline:none;
    font-weight:bold;
    padding:5px 8px;
}
.display_articole_button:hover{
    cursor:pointer;
    background:#fbfbfb;
    color:#1dbc60;
    border: 2px outset #1dbc60;
}
.volume{
    display:block;
    font-size:1.125rem;
    font-weight:bold;
    color:#1dbc60;
    margin-left:3%;
}
.autori_titlu{
    margin-top:0.625rem;
    display:block;
    font-size:18px;
    font-weight:bold;
    color:#1dbc60;
    margin-left:3%;
}
.despreContainer{
    border:1px solid lightgrey;
    overflow:hidden;
    position:relative;
    width:98%;
    font-size:1.125rem;
    margin:0 auto;
    display:flex;
    flex-direction:row;
    background-image: url('../../../images/arhiva_background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color:#fbfbfb;
    position:relative;
    border:1px solid #f1f1f1;
    margin-left:1%;
}
.despreOverlay{
    position:absolute;
    min-width:100%;
    max-width:100%;
    min-height:100%;
    max-height:100%;
    overflow:hidden;
    display:flex;
    background-color:rgba(170, 96, 96, 0.5);
    z-index:10;
}
.despre_pontica{
    width:100%;
    margin:auto;
    padding:0.938rem 0.938rem;
    z-index:100;
}
.descarca{
    margin-right:100px;
    display:inline-block;
}
.divarticol{
    display:flex;
    flex-direction:row;
    width:100%;
    font-size:18px;
}
.divarticol_gri{
    background:#fbfbfb;
    display:flex;
    flex-direction:row;
    width:100%;
    margin-top:10px;
    margin-bottom:10px;
    border-top:1px solid #cdcdcd;
    border-bottom:1px solid #cdcdcd;
    font-size:18px;
}

.autori{
    width:18%;
    margin-left:4%;
    font-size:18px;
}
.autor_link{
    color:inherit;
}
.autori_link{
    display:block;
}
.container_autor{
    display:flex;
    flex-direction:column;
}
.container_autor :hover{
    background-color:#1dbc60;
    color:white;
    transition: all 0s ease-in-out;
}
.divTitlu{
    padding:20px 0px;
    background:#fbfbfb;
    display:flex;
    flex-direction:row;
    width:100%;
    margin-top:10px;
    margin-bottom:10px;
    border-top:1px solid #cdcdcd;
    border-bottom:1px solid #cdcdcd;
    font-weight:bold;
    font-size:22px;
}
.div_titlu_header_articol{
    width:78%;
}
.div_titlu_header_autori{
    width:18%;
    margin-left:4%;
}
.gri{
    display:flex;
    flex-direction:row;
    width:100%;
    background:#fbfbfb;
    vertical-align:middle;
    width:94%;
    margin-left:auto;
    margin-right:0;
    padding:10px 0px;
}

.alb{
    display:flex;
    flex-direction:row;
    width:100%;
    background:white;
    vertical-align:middle;
    width:94%;
    margin-left:auto;
    margin-right:0;
    padding:10px 0px;
}
.display_search_results{
    height:auto;
    width:auto;
    margin:left;
    overflow:auto;
}
.display_search_results span{
    margin-right:10px;
    display:inline-block;
}
.search_button{
    padding:0px 5px;
    background:#1dbc60;
    font-size:18px;
    color:#fbfbfb;
    font-weight:bold;
    cursor:pointer;
    border:none;
    border: 2px outset #1dbc60;
    border-radius:5px;
    margin:auto;
    margin-left:0;
    margin-top:15px;
    outline:none;
}
.search_button:hover{
    background:#fbfbfb;
    color:#1dbc60;
    border: 2px outset #1dbc60;

}
.search_button:hover a{
    color:#1dbc60;
}
.search_input{
    width:130px;
    border-radius:5px;
    outline:none;
    border:1px solid #1dbc60;
    height:31px;
    background:#fbfbfb;
    margin:auto;
    margin-left:-20px;
    margin-top:15px;
    font-size:20px;
    color:#1dbc60;
}
.search_input:focus{
    background: #f4fdf4;
    box-shadow: 0 0 0 1px #1dbc60;
    border-radius: 5px;
}
.search{
    display:flex;
    vertical-align:middle;
}
.iconite_descriere_arhiva{
    margin-right:30px;
    margin-bottom:20px;
}
.epontica_links_container{
    display: flex;
  width: 95%;
  justify-content: center;
}

    
.linkuri_descriere_arhiva{
    color:#1dbc60;
    font-weight:bold;
}
.linkuri_descriere_arhiva:hover{
    color:white;
}
.arhiva_header{
    width:100%;
    margin:auto;
    vertical-align:middle;
    margin-bottom:1.563rem;
}
.iconita_arhiva{
    font-size:1.9rem;
    margin-top:auto;
    margin-bottom:auto;
    margin-left:auto;
}

.arhiva_header h1{
    font-size:2rem;
    display:inline-block;
    margin:auto;
    margin-top:auto;
    margin-bottom:auto;
    margin-left:10px; /*distanteaza iconita de scris*/
}
.logo_and_title{
    margin:auto;
    margin-bottom: 1rem; 
    text-align:center;
    display:flex;
    width:97%;
    justify-content:center;
}
.continut_card{
    display:flex;
    flex-direction:row;
    margin-top:auto;
    position:relative;
}
.detalii_volum_container{
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:68%;
    gap:0;
    margin:auto;
    margin-right:0;
    position:relative;
    margin-bottom:0;
}
.detalii_volum{
    display:inline-block;
    font-size:1.25rem;
    width:94%;
    margin-left:auto;
    margin-right:0;
}
.card{
    overflow:visible;
    font-size:1.25rem;
    padding:0rem 0.625rem;
    cursor:auto;
    width:95%;
    border:1px solid #f1f1f1;
    margin-top:1%;
}
.casuta{
    overflow:hidden !important;
    top:-15px;
    margin:0 !important;
}
.casutavolume{
    position:relative;
    display:flex;
    flex-direction:column;
    padding:0;
    overflow:auto;
    height:10rem;
    background: #fbfbfb;
    border-radius:0.313;
    padding:0.625rem;
    margin-bottom:.6rem;
}
.span_de_la_server{
    position:absolute;
    left:360px;
    margin-left:auto;
}
.display_none{
    position:absolute;
    top:0;
    display:none;
}
.paginatie_an{
    margin-right:0;
    display:inline-block;
    margin:auto;
    position:absolute;
    right:0;
    text-align:left;
    width:50%;
}
.lista_toti_autorii{
    position:relative;
    display:flex;
    flex-direction:column;
    padding:0;
    overflow:auto;
    text-align:left;
    height:10rem;
    background: #fbfbfb;
    border-radius:5px;
    padding:10px;
    
}
.lista_toti_autorii a{
    font-size:18px;
}
.lista_toti_autorii a:hover{
    color:#1dbc60;
    cursor:pointer;
}



.active{
    color:#1dbc60;
    font-weight:bold;
}


.volume_selectate span, .autori_selectati span{
    font-weight:bold;
    font-size:18px;
    color:#1dbc60;
}
.autori_selectati_titlu, .volume_selectate_titlu{
    font-weight:bold;
    font-size: 18px;
    color:#1dbc60;
    margin-left:3%;
}
.volume_selectate, .autori_selectati{
    padding-left:3%;
    background:#fbfbfb;
    overflow:auto;
    max-height:7rem;
    border-radius:5px;
    padding:10px;
}
.volume_selectate p, .autori_selectati p{
    margin-bottom:0;
}





.volum, .autor{
    margin-bottom:5px;
    margin-top:auto;
    margin-bottom:auto;
}

.volum:hover, .autor:hover{
    color:#1dbc60;
    cursor:pointer;
}


.check_volum, .check_autor{
    color: #fbfbfb;
    padding: 5px 8px;
    background:#1dbc60;
    font-size:20px;
    outline:none;
    border:1px solid #1dbc60;
    border-radius:5px;
    font-weight:bold;
    cursor:pointer;
    margin-top:auto;
    margin-bottom:auto;
    margin-right:3%;
}


.filtreaza{
    padding:0px 5px;
    background:#1dbc60;
    font-size:18px;
    color:#fbfbfb;
    font-weight:bold;
    cursor:pointer;
    border:none;
    border: 2px outset #1dbc60;
    border-radius:5px;
    margin:auto;
    margin-left:0;
    margin-top:15px;
    outline:none;
}

.filtreaza:hover{
    background:#fbfbfb;
    color:#1dbc60;
    border: 2px outset #1dbc60;
}


.articole_filtrate_container{
    background:white;
    display:flex;
    font-size:1.125rem;
   
}

.articole_filtrate_container_gri{
    background:#fbfbfb;
    display:flex;
    font-size:1.125rem;
   
}

.casuta_afisare_filtru{
    width:95%;
   
}


.numar_articol_filtrat{
    padding:.5rem;
    color:black;
    font-weight:bold;
    margin-left:0;
    margin-right:auto;
}

.numar_articol_filtrat:hover{
    color:#1dbc60;
}

.titlu_volum_filtrat{
    width:20%;
    padding:5px;
    color:black;
    margin-right:0.4rem;
    margin-left: 0.313rem;
}

.titlu_articol_filtrat{
    width:50%;
    padding:5px;
    color:black;
    margin-right:0;
    margin-left: 0 !important;
    display:inline;

}
.titlu_articol_filtrat a{
    text-decoration:none;
    color:inherit;
    
}
.titlu_articol_filtrat a:hover{
    color:#1dbc60;
}

.autori_articol_filtrat{
    width:19%;
    padding:5px;
    margin-right:0;
    margin-left:auto;
}

.container_autor_filtrat{
    display:flex;
    flex-direction:column;
    padding:5px;

}
.container_autor_filtrat :hover{
    background-color:#1dbc60;
    color:white;
    transition: all 0s ease-in-out;
}


.search_link{
    color:white;
}
.search_link:hover{
    color:#1dbc60;
}

.revista_titlu{
    margin-top: .5rem;
    font-size:1.5vw;
}


@media only screen and (min-width:100px) and (max-width:767px){
    .bigContainer{
        max-width:96%;
        margin-top: 1rem !important;
    }
   
    .CardsContainer{
        max-width:98%;
        margin-left: .3rem;
    }
    .separator{
        width:98%;
        display: flex;
    }
    .linie >h1 { /*titlu*/
        color:black;
        font-size:2.6vw;
        max-width:96%;
    }
    .logo_and_title{
        max-width:98%;
        
    }
    .titlu_arhiva{
        width:98%;
    }
  
    .arhiva_header{
        width:100%;
        margin:auto;
        vertical-align:middle;
        margin-bottom:0;
    }
  
   
    .despre_pontica > a > img{
       
        position: relative;
    }
    
   .continut_card> a> img{
    width:10rem;
    height:15rem;
    margin-bottom:0;
    }
    
    .detalii_volum_container{
        height:14.5rem;
    }
    .alb {
        display: flex;
        font-size: .6rem;
        padding: 0 0 0 0 !important;
    }
    .gri{
        font-size: .6rem;
    }

   .smallContainer{
    display:flex;
    width:98%;
    margin: 0 auto;
    overflow:visible;
    }

    .revista_titlu{
        font-size:3vw;
    }
    .continut_card{
        display:flex;
        width: 97% !important;
        position:relative;
    }

    .ant-card-body{
    padding: 0.313rem 0.625rem !important;
    }
    .ant-card-hoverable{
        max-width: 97%;
    }
    
   
    
    /*toate articolele => detalii autor */
  
   
}
    
   


@media only screen and (min-width:150px) and (max-width:767px){
    .bigContainer{
        max-width:96%;
        margin-top: 1rem !important;
    }
    .smallContainer{
        display:flex;
        width:98%;
        margin: 0 auto;
        overflow:visible;
        flex-direction: column;
        overflow:hidden;
    }
    .CardsContainer{
        max-width:98%;
    }
    .logo_and_title{
        max-width:98%;
    }
    .linie >h1 {  /*titlu*/
        color: black;
        font-size:1rem;
        max-width:96%;
        margin-top: .5rem;
    }
    span{
        margin-left: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .arhiva_header{
        width:97%;
        display: flex;
        flex-direction: row;
        margin-bottom:0;
        align-items: center;
    }
    
    .despre_pontica > p {
        font-size: 4vw;
    };
   
    .despre_pontica > a > img{
    
       position: relative;
    }
   
    .revista_titlu{
        font-size:3vw;
    }
    .display_articole_button{
        background:#1dbc60;
        border:none;
        border-radius:5px;
        color:#fbfbfb;
        border: 2px outset #1dbc60;
        outline:none;
        font-weight:bold;
        padding:5px 8px;
        font-size: 12px !important;
    }
    .span_de_la_server{
        left: 100px;
        margin: 0 !important;
        font-size: 10px !important;
        padding: 0px 0px 15px;
       
    }
    .pdf_icon{
        padding-top: 5px;
       
    }
    
    .numar_articol_filtrat{
        padding:5px;
        color:black;
        font-weight:bold;
        font-size: 3.5vw !important;
    }
    .articole_filtrate_container{
        background:white;
        gap:3%;
        font-size:0.5rem;
    }
      
    .articole_filtrate_container_gri{
        background:#fbfbfb;
        gap:2%;
        font-size:0.5rem;
      
        
    }
    .titlu_volum_filtrat{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        color:black;
        font-size: 3.5vw !important;
    }
    .titlu_articol_filtrat{
        width:43% !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        color:black;
        margin-right:0;
        margin-left:.4rem;
       
        font-size: 3.5vw !important;
      
    }
    .autori_articol_filtrat{
      
        display: flex;
        flex-direction: column;
        justify-content:start;
        margin-right:0.625rem;
        font-size: 3.5vw !important;
    }

    .divTitlu{
        padding:0 !important;
        background:#fbfbfb;
        display:flex;
        flex-direction:row;
        width:100%;
        font-weight:bold;
        font-size:.8rem;
    }
    .divarticol{
        display:flex;
        flex-direction:row;
        width:100%;
        font-size:.7rem;
    }

    .divarticol span{
        font-size:.7rem;
    }

    .divarticol_gri{
        background:#fbfbfb;
        display:flex;
        flex-direction:row;
        width:100%;
        margin-top:10px;
        margin-bottom:10px;
        border-top:1px solid #cdcdcd;
        border-bottom:1px solid #cdcdcd;
        font-size:.7rem;
    }

    .divarticol_gri span{
        font-size:.7rem;
    }

    .container_autor{
        display:flex;
        flex-direction:column;
        font-size:.7rem;
    }
    .autori{
        font-size:.7rem;
        display: flex;
        flex-direction: column;
        justify-content:start;
        margin-right: 1rem;
    }
  
        
    .wrapper h2 {
        font-size: 4vw !important;
    }
    .wrapper p {
        font-size: 3vw !important;
    }
  
    .epontica_links_container{
        
        justify-content: center;
        width: 97% !important;
        margin-left: 0 !important;
        
    }
    .epontica_links_container > a> img{
        width: 97% !important;
    }

    .iconite_descriere_arhiva{
        width: 40%;
      
    }
    .left_column{
      padding: 0 !important;
      padding-bottom: 0 !important;
      width: 90%;
      flex-direction: column;
      background-color:white;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    
    .left_column .bigContainer{
        display: flex; 
        justify-content: flex-start; 
        position:sticky;
       
    }
    
    .volume{
        display:block;
        font-size:.9rem;
        font-weight:bold;
        color:#1dbc60;
        margin-left:3%;
        margin-bottom: .3rem;
    }
    .casutavolume{
        position:relative;
        display:flex;
        flex-direction:column;
        padding:0;
        overflow:auto;
        text-align:left;
        height:7rem;
        background: #fbfbfb;
        border-radius:0.313;
        padding:0.625rem;
    }
    .lista_toti_autorii{
        position:relative;
        display:flex;
        flex-direction:column;
        overflow:auto;
        text-align:left;
        height:7rem;
        background: #fbfbfb;
        border-radius:5px;
        padding:0.625rem;
        margin-bottom: .8rem;
        margin-top: .3rem;
    }
    .autori_titlu{
     
        display:block;
        font-size:.9rem;
        font-weight:bold;
        color:#1dbc60;
        margin-left:3%;
    }
    .volume_selectate_titlu{
        margin-top: .3rem;
        font-size: .9rem;
    }
    .autori_selectati_titlu{
        font-size: .9rem;
    }
    .filtreaza{
        margin-top: -.5rem;
        font-size: .8rem;
    }
    .search_button{
        font-size: .8rem;
    }
    .search_input{
        width: 40%;
        margin-bottom: 6px;
    }
    .iconita_arhiva{
        font-size:1rem;
        margin-top:auto;
        margin-bottom:auto;
        margin-left:auto;
    }
    .ant-card-body{
      
        padding: 0.313rem 0.625rem !important;
        max-width: 97%;
    }
    .ant-card-hoverable{
            max-width: 97%;
    }
    .casuta{
      
        max-width: 97%;
    }
    .continut_card{
        display:flex;
        flex-direction: row;
        max-width: 97% !important;
        position:relative;
       
    }
    .continut_card> a> img{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:97%;

    }
    
    .detalii_volum_container{
       width: 50%;
       margin-left: 0 !important;
    }
    .detalii_volum{
        position:relative;
        display:flex;
        flex-direction:column;
        align-items: flex-start;
    }
  
}

