.editare_autori_container{
    width:30%;
    margin:auto;
    background:white;
    border:1px solid #cdcdcd;
    border-radius:5px;
    max-height:600px;
    overflow:auto;
    padding:20px;
}

.casuta_editare_autori{
    position:relative;
}
.editare_autori_titlu{
    width:30%;
    margin:auto;
}
.numar_autor{
    font-weight:bold;
}
.link_editare_autor{
    position:absolute;
    left:50px;
}

.link_editare_autor:hover span{
    color:#1dbc60;
}