.container_blog{
    display:flex;
    width: 97% !important;   
    /* flex-direction:row; */
}
/* .container_articole{
    width:70%;
    margin-right:0;
    margin-left:auto;
} */

.left_column{
    margin-left:0;
    margin-right:auto;
}
.pagination_div >ul {
    position:relative;
    display:flex;
    flex-direction:row !important;
    padding:0;
    overflow:auto;
    text-align:left;
    background: #fbfbfb;
    border-radius:5px;
    height:40px !important;
    margin-top:20px;
}


.pagination_div >ul li a{
    padding: 0px 3px;
    margin-top:auto;
    margin-bottom:auto;
}
.pagination_div >ul li a{
    color:#1dbc60;
}
.pagination_div >ul li a:hover{
    color:#1dbc60;
}

@media only screen and (min-width:150px) and (max-width:767px){
  .container_blog{
    display:flex;
    width:98%;
    margin: 0 auto;
    overflow:visible;
    flex-direction: column;
    overflow:hidden;
    /* display: flex; */
    /* justify-content: flex-end; */
  }
}


