@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  padding:0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
font-size: 14px;
line-height: 1.5;
color: #24292e;
}
pre {
  padding: 10px;
  white-space: pre-wrap;
  background-color: #333333;
  color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}
input.error {
  border-color: red;
}
.input-feedback {
  color: red;
  height: 5px;
  margin-top: -12px;
}
.ql-editor iframe {
  min-height: 300px;
}
.ql-editor img {
  max-height: 300px;
  object-fit: contain;
}
.postPage iframe {
  min-height: 400px;
}
*,*:before,*:after {
box-sizing: border-box;
}
html,
body {
  min-height: 100vh;
  position: relative;
}
#root{
  width:100%;
  min-height: 100vh; 
  overflow: hidden;
  display: block;
  position: relative;
  /*height of your footer */
  padding-bottom: 600px; 
}