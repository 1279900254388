.autor_heading{
    margin-top:100px;
    /* padding-top:100px; */
    text-align:center;
}
.pagina_autor_container{
    /* border:1px solid red; */
    width:80%;
    margin:auto;
    background-color:#fbfbfb;

    width:75%;
    margin:0 auto;
    margin-top:50px;
    font-size:18px;
    /* font-family:Verdana; */
    font-weight:500;
    /* border: 2px solid lightgrey; */
    padding:50px 150px;
    /* margin-bottom:200px; */
    /* box-shadow: 3px 3px 3px lightgoldenrodyellow; */
    /* box-shadow: 3px 3px 3px lightgrey; */
    /* box-shadow:4px 9px   lightgrey; */
    /* box-shadow: 10px 10px 8px #888888; */
    /* background:lightgoldenrodyellow; */
    background:#fbfbfb;
    border-radius:10px;


    /* -webkit-box-shadow: 0 0 10px 10px lightgrey;
    -moz-box-shadow: 0 0 10px 10px lightgrey;
    box-shadow: 0 0 10px 10px lightgrey; */


    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.separator_articole_autor{
    width:97%;
 
}


/* .underline_container_recenzare{
    width:40%;
    
} */
@media only screen and (min-width:150px) and (max-width:767px){
    .separator_articole_autor span{
        /* font-size: 1.5rem;
        text-align: end; */
        display: none;
    }
}

