@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  padding:0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
font-size: 14px;
line-height: 1.5;
color: #24292e;
}
pre {
  padding: 10px;
  white-space: pre-wrap;
  background-color: #333333;
  color: white;
  border-radius: 10px;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}
input.error {
  border-color: red;
}
.input-feedback {
  color: red;
  height: 5px;
  margin-top: -12px;
}
.ql-editor iframe {
  min-height: 300px;
}
.ql-editor img {
  max-height: 300px;
  object-fit: contain;
}
.postPage iframe {
  min-height: 400px;
}
*,*:before,*:after {
box-sizing: border-box;
}
html,
body {
  min-height: 100vh;
  position: relative;
}
#root{
  width:100%;
  min-height: 100vh; 
  overflow: hidden;
  display: block;
  position: relative;
  /*height of your footer */
  padding-bottom: 600px; 
}
.container{
    width:99%;
    margin:0 auto;
    overflow: hidden;
}
.homepage_div{
    display:flex;
    justify-content:space-around;
    margin:0 auto;
    height:auto;
}
.homepage_subdiv_left{
    width:24%;
    margin-top:0;
    overflow:hidden;
    animation: 1.1s ease-in-out 0s 1 bounce_left;
    animation-delay:500ms;
    animation-fill-mode:both;
    height:auto;
    margin-left:-27%;
}
.homepage_subdiv_right{
    width:24%;
    overflow:hidden;
    margin-top:0;
    height:auto;
    margin-right:-27%;
    animation: 1.1s ease-out 0s 1 bounce_right;
    animation-delay:500ms;
    animation-fill-mode:both;
}
.homepage_subdiv_center{
    width:49%;
    overflow:hidden;
    height:auto;
    animation: 1.1s ease-out 0s 1 bounce_top;
    animation-delay:500ms;
    animation-fill-mode:both;
    position:absolute;
    z-index:1000;
    overflow:visible;
}
.overlay {
    position:absolute;
    bottom:0;
    top:0;
    left:0;
    right:0;
    overflow:hidden;
    background-color:rgba(0,0,0,0.25);
    cursor:pointer;
}
.overlay_left p{
    opacity:1;
    color:white;
    font-size: 26px;
    font-weight:600;
    margin: auto;
    /* text-align:center; */
}
.overlay_right p{
    opacity:1;
    color:white;
    font-size: 26px;
    font-weight:600;
    margin: auto;
    /* text-align:center; */
}
.overlay_right{
    position:absolute;
    bottom:0;
    top:0;
    left:0;
    right:0;
    min-width:100%;
    max-width:100%;
    overflow:hidden;
    display:flex;
    background-color:rgba(0,0,0,0.25);
    cursor:pointer;
}
.overlay_left{
    position:absolute;
    bottom:0;
    top:0;
    left:0;
    right:0;
    min-width:100%;
    max-width:100%;
    overflow:hidden;
    display:flex;
    background-color:rgba(0,0,0,0.25);
    cursor:pointer;
}
@keyframes bounce_top {
    0%{transform: translateY(-100%);} 
    40% {transform: translateY(5%);}
    60% {transform: translateY(-5%);} 
    80%{transform:translateY(+2%)}
}  
@keyframes bounce_left {
    0%{transform: translateX(-100%);} 
    40% {transform: translateX(5%);}
    60% {transform: translateX(-5%);} 
    80% {transform: translateX(2%);} 
} 
@keyframes bounce_right {
    0%{transform: translateX(+100%);} 
    40% {transform: translateX(-5%);}
    60% {transform: translateX(+5%);} 
    80% {transform: translateX(-2%);} 
} 
.image{
    width:100%;
    height:100%;
    object-fit:contain;
    transition: transform 1s ease;
}
.image_div{
    margin-bottom:2%;
    overflow:hidden;
    position:relative;
    display:flex;    
    justify-content: center;
    align-items: center;
    max-width:100%; 
}
.image_div:hover img{
    transform: scale(1.3);
    overflow:hidden;
}
.center_image_div{
    margin-top:0;
    overflow:hidden;
    width:100%;
    height:auto;
    max-width:100%; 
    animation:1.1s ease-out 0s 1 bounce_top; 
    animation-delay:500ms;
    animation-fill-mode:both;
}
.centru{
    display:block;
    text-align:left;
}
.rand1{
    font-size:9vmin;
    text-align:center;
    font-weight:bold;
}
.rand2{
    font-size:5vmin;
    padding-bottom:120px;
    text-align:center;
    margin-top:-80px;
    font-weight:bold;
    width:100%;
}
.rand3{
    font-size:3vmin;
    text-align:center;
    font-weight:bold;
    line-height:0px;
}
.rand4{
    font-size:3vmin;
    text-align:center;
    font-weight:bold;
    line-height:80px;
}
.rand5{
    font-size:3vmin;
    text-align:center;
    font-weight:bold;
    line-height:100px;
}
.center_title_div{
    width:50%;
    text-align:center;
    margin-left:10px;
}
.overlay_center{
    position:absolute;
    bottom:0;
    top:0;
    left:0;
    right:0;
    min-width:100%;
    max-width:100%;
    overflow:hidden;
    display:flex;
    background-color:rgba(0,0,0,0.1);
    cursor:pointer;
    color:white;
}
.overlay_left p, .overlay_center p, .overlay_right p{
    font-size:1.2vw;
}
body{
    border-top:14px solid black;
}

/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/
/* ===============media queries  ==================*/

@media only screen and (min-width:100px) and (max-width:767px){
    .overlay_left p, .overlay_center p, .overlay_right p{
        font-size:4vw;
    }
    #root{
        padding-bottom:400px;
    }
    .container .homepage_div{
        width:96%;
        margin:5px auto;
        margin-top:0;
        height:auto;
        margin-bottom:5vh;
        height:auto;
        display:flex;
        flex-direction:column;
        overflow:hidden;
    }
    #image1{
        margin-top:0;
    }
    .homepage_subdiv_left, .homepage_subdiv_center, .homepage_subdiv_right{
        width:100%;
        display:flex;
        flex-direction:column;
        margin: 0 auto;
        overflow:hidden;
        animation:none;
        position:relative;
        animation:1.1s ease-out 0s 1 bounce_left; 
        animation-delay:500ms;
        animation-fill-mode:both;
    }
    .image_div, .center_image_div{
        width:100%;
        margin:1% auto;
        animation:none;
        animation:1.1s ease-out 0s 1 bounce_left; 
        animation-delay:500ms;
    }
    .image{
        width:100%;
    }
    .overlay_left p, .overlay_center p, .overlay_right p{
        font-size:5vw !important;
    }
    .container, .homepage_div{
        width:96%;
    }
    #image1{
        margin-top:0;
    }
}

/* ==============Landscape mode================== */
/* ==============Landscape mode================== */
/* ==============Landscape mode================== */
/* ==============Landscape mode================== */
/* ==============Landscape mode================== */
/* ==============Landscape mode================== */

@media only screen and (min-width:100px) and (max-width:767px) and (orientation:landscape){
    .overlay_left p, .overlay_center p, .overlay_right p{
        font-size:4vw;
    }
    #root{
        padding-bottom:400px;
    }
    .container .homepage_div{
        width:96%;
        margin:5px auto;
        margin-top:0;
        height:auto;
        margin-bottom:5vh;
        height:auto;
        display:flex;
        flex-direction:column;
        overflow:hidden;
    }
    #image1{
        margin-top:0;
    }
    .homepage_subdiv_left, .homepage_subdiv_center, .homepage_subdiv_right{
        width:100%;
        display:flex;
        flex-direction:column;
        margin: 0 auto;
        overflow:hidden;
        animation:none;
        position:relative;
        animation:1.1s ease-out 0s 1 bounce_left; 
        animation-delay:500ms;
        animation-fill-mode:both;
    }
    .image_div, .center_image_div{
        width:100%;
        margin:1% auto;
        animation:none;
        animation:1.1s ease-out 0s 1 bounce_left; 
        animation-delay:500ms;
    }
    .image{
        width:100%;
    }
    .overlay_left p, .overlay_center p, .overlay_right p{
        font-size:5vw !important;
    }
    .container, .homepage_div{
        width:96%;
    }
    #image1{
        margin-top:0;
    }
}

/*320, 481, 641, 961, 1025, 1281*/





.wrapper{
  width:97%;
  margin:0 auto;
  margin-top:50px;
  font-size:18px;
  font-family: 'Quicksand', sans-serif;
  font-weight:500;
  padding:50px 50px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px, rgba(0, 0, 0, 0.23) 0px 2px 2px;
  background:white;
  border-radius:5px;
}


.wrapper h2{
  text-align :center;
  padding-bottom:10px;
  /* padding-left: 5vw; */
}
.wrapper h3{
  margin-top: 1.5vw;
}


li{
  list-style-type: none;
}
.underline{
  width:42%;
  display:inline-block;
  text-align:center;
  vertical-align:middle;
  border-bottom:2px solid #1dbc60;
  margin-left:2%;
  margin-right:2%;

}
.underline_container{
  margin:0 auto;
  width:42%;
  margin-bottom:30px;
  margin-top:-20px;
}
span{
  color:grey;
  font-size:20px;
}
.icon_check{
  color:#1dbc60;
  margin-right:10px;
}
.descarca_sablon{
  font-weight:bold;
  /* border:1px solid #1dbc60; */
  border: 2px outset #1dbc60;
  outline:none;
  padding:8px 12px;
  font-size:20px;
  border-radius:5px;
  background:#1dbc60;
  color:white;
}
.descarca_sablon:hover{
  background:white;
  color: #1dbc60;
  cursor:pointer;
  border: 2px outset #1dbc60;
}





/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */



@media only screen and (min-width:100px) and (max-width:767px){
  .wrapper{
    width:99%;
    font-size:4vw;
    padding:5%;
    border-radius:1px;
    margin-top:0;
    box-shadow:none;
  }
  .descarca_sablon{
    font-size:4vw;
    padding:1vw 2vw ;
    border-radius:1px;
  }

  .underline_container{
    display:none;
  }
  .underline_recenzare{
    display:none !important;
  }
  .steluta{
    display:none;
  }
}


/* ================landscape mode========================= */
/* ================landscape mode========================= */
/* ================landscape mode========================= */
/* ================landscape mode========================= */
/* ================landscape mode========================= */
/* ================landscape mode========================= */
/* ================landscape mode========================= */


@media only screen and (min-width:100px) and (max-width:1080px) and (orientation:landscape){
  .wrapper{
    width:99%;
    font-size:2vw;
    padding:5%;
    border-radius:1px;
    margin-top:0;
    box-shadow:none;
  }
 
  .descarca_sablon{
    font-size:2vw;
    padding:1vw 2vw ;
    border-radius:1px;
  }

  .underline_container{
    display:none;
  }
  .underline_recenzare{
    display:none;
  }
  .steluta{
    display:none;
  }
}
.underline_recenzare{
    width:30%;
    display:inline-block;
    text-align:center;
    vertical-align:middle;
    border-bottom:2px solid #1dbc60;
    margin-left:2%;
    margin-right:2%;
    
}
.underline_container_recenzare{
    display:flex;
    /* flex-direction:column; */
    width: 35vw;
    margin:auto !important;
    text-align:center;
    margin-bottom: 2vw;
}

.separator_recenzare{
    margin-left:10px;
}
.procesul{
    margin-bottom:-10px;
}
.separator span{
    vertical-align:middle;
    /* border:1px solid red; */
}
.myicon{
    text-align:center;
    margin-right:16px;
}


/* luat de la epontica*/
.epontica_links_container{
    display:flex;
    margin:0 auto;
    /* width:40%; */
    /* margin-left:31.6vw; */
    
    margin-bottom: 20px;
}

.paragraf_epontica{
    /* width:70vw; */
    margin:auto;
    font-size:16px;
    text-align:justify;
}

@media only screen and (min-width:150px) and (max-width:767px){
    .underline_container_recenzare{
        display:flex;
        flex-direction:row;
        width: 97%;
        margin:auto !important;
        text-align:center;
        margin-bottom: 2vw;
    }
    .procesul{
        display: flex;
        /* text-align: center; */
    }
    .separator_recenzare{
        width: 96%;
    }
   .underline_recenzare{
       width: 80%;
   }
}

.underline_recenzare{
    width:20%;
    display:inline-block;
    text-align:center;
    vertical-align:middle;
    border-bottom:2px solid #1dbc60;
    margin-left:2%;
    margin-right:2%;  
}
.underline_container_redactie{
    display:flex;
    /* flex-direction:column; */
    width:30%;
    margin:auto;
    text-align:center;
    margin-bottom:30px;
}
.separator{
    margin:0 auto;
    width:70%;
    margin-bottom:30px;
    /* margin-top:-20px; */
    /* border:1px solid red; */
    margin:0;
}
.separator span{
    vertical-align:middle;
}
.separator h2{
    margin-bottom:-10px;
}
.myicon{
    /* border:1px solid red; */
    margin:0;
}
.paragraf_colegiu h3{
    border: 2px outset #1dbc60;
    display:inline-block;
    padding:5px 10px;
    background:#1dbc60;
    border-radius:5px;
    color:#fbfbfb;
}
.separator_colegiu{
    width:100%;
}


/* =================media queries================= */
/* =================media queries================= */
/* =================media queries================= */
/* =================media queries================= */
/* =================media queries================= */
/* =================media queries================= */
/* =================media queries================= */





.paragraf_epontica{
    /* width:70vw; */
    margin:auto;
    font-size:16px;
    text-align:justify;
}
/* //Pontica 1, 23, 24, 27 */

.ant-menu{
  all: reset;
  /* all:reset;
  all:unset; */
}
.ant-menu-item{
  all:reset;
}
.nav a{
  all:reset; 
}
.nav li{
  all:reset;
} 

.nav a::before{
  all:reset;
}
.menu__logo {
  width: 100px;
  height:100px;
  width:5vw;
  height:5vw;
  float: left;
  background-image:url(/static/media/logo.a017edb2.png);
  background-size:contain;
  background-position:center;
  background-repeat:no-repeat;
  margin:0;
  padding:0;
  border:none;
  border-radius:100%;
}
.logo_container{
  display:flex;
  width:20vw;
}
.logo_container p{
  font-size:1vw;
  color:black;
  width:270px;
  width: 13vw;
  margin:auto;  
  line-height:24px;
  line-height:1.4vw; 
}
.menu__container .ant-menu-submenu-title {
  padding: 10px 20px;
}
.menu__container .menu_left {
  float: left;
}
.menu__container .menu_right {
  float: right;
}
.menu_drawer .ant-drawer-body {
  padding: 0 !important;
}
.nav ul a{
  border-bottom:0.04rem solid #d3d3d3 ;
  border-top:0.04rem solid #d3d3d3 ;
  border-left:0.04rem solid #d3d3d3 ;
}
.ant-menu-item{
  padding:0 !important;
  border-bottom:none !important;
}
.admin{
  border-right:0.07rem solid #d3d3d3;
}
.contact{
  border-right:0.07rem solid #d3d3d3;
}
.logout{
  border-right:0.07rem solid #d3d3d3;
}
.inregistrare{
  border-right:0.07rem solid #d3d3d3;
}

/******************************************** MENIU.css *********************************************/

.nav li{
  display:block;
  margin:0;
}
.nav a{
  text-decoration:none;
  height:100%;
  text-align:center;
  display:block;
  color:black;
  font-size: 0.8vw;
  line-height:3vw;
}
.nav a:hover{
  color:#1dbc60!important;
  transition: all 0.5s ease-in-out;
}
.nav_links{
  display:flex;
  border-bottom:none !important;
}
.ant-menu{
  /* vertical-align:center; */
  margin-top:auto;
  margin-bottom:auto;
}
.active_link {
  color:#1dbc60!important;
  background:#fbfbfb !important;
  z-index:9999;
}
.menu__mobile-button {
  display: none !important; /* use of important to overwrite ant-btn */
  background: #1dbc60;
}
.center_link{
  display:block;
  z-index:11111;
  width:100%;
  height:100%;
  border:none !important;
}
.nav{
  display: flex;
  margin-bottom:0px;
  font-weight:600;
  list-style-type: none;
  justify-content:space-between;
  vertical-align: center;
  margin: 0 0.5%;
  padding:0;
}
.ant-drawer-wrapper-body{
  background:#fbfbfb;
}
.ant-drawer-header{
  background:#1dbc60;
  width:100%;
  border-radius:0px !important;
}
.ant-drawer-title{
  color:#fbfbfb;
  margin-left:11.5%;
}
.ant-menu-item-selected{
  padding:30px;
  background:#fbfbfb !important;
}
.iconita_drawer{
  vertical-align:middle !important;
  margin:auto;
  color:#1dbc60;
  display:flex;
  font-size:40px;
}
.menu__logo a{
  border-right:none !important;
}

/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */
/* ===============media queries =================== */


/* pentru meniul 'drawer' , se aplica doar dimensiunilor sub 768px. De aceea am facut un @media separat pt asta */
@media only screen and (max-width:767px){
  .ant-drawer-content-wrapper{ 
    width:70px !important;
  }
  .menu__mobile-button {
    display: inline-block !important;
  }
  .menu_left,
  .menu_right {
    display: none;
  }
  .menu__container .ant-menu-item,
  .menu__container .ant-menu-submenu-title {
    padding: 1px 20px;
  }
  .ant-menu{
    background:#fbfbfb;
  }
  .ant-drawer-header{
    padding-left:10vw;
  }
  .ant-drawer-title{
    font-size:1rem;
    display:block;
    color:white;
    margin-left:0;
  }
  .ant-drawer-body{
    padding:0 !important;
    margin: 20px auto;
    width:75% ;
  }
  .ant-menu-item{
    font-size:0.23rem !important;
    margin-bottom:5px !important;
    height:0.2vw !important;
    line-height:0.2vw !important;
    padding:6px 3px !important;
    border:1px solid #d3d3d3 !important;
    width:100%;
    background:white !important;
    border-radius:2px;
    margin:auto;
  }
  .ant-menu-item-selected::after{
    display:none !important;
  }
  .ant-menu-item-selected{
    background:#fbfbfb !important;
  }
  .admin{
    border-right:none;
  }
  .contact{
    border-right:none;
  }
  .logout{
    border-right: none;
  } 
}

@media only screen and (min-width:100px) and (max-width:767px) {
  .ant-menu-submenu{
    display:none !important;
  }
  .menu__mobile-button {
    height:auto;
    background:#fbfbfb;
    border:none;
    padding:0 !important;
    vertical-align:middle !important;
    box-shadow:none;
    max-width:15%;
    padding:0 !important;
    /* flex-shrink:1; */
  }
  .menu__mobile-button:hover{
    background:none;
  }
  .drawer{
    z-index:99999;
  }  
  .logo_container p{
    font-size:4.2vw;
    line-height:4.6vw;
    color:black;
    text-align:center;
    margin:auto;
    display:inline-block;
    display:flex;
    vertical-align:middle;
    width:70%;
  }
  .menu__logo{
    margin-left:0;  
    width:18vw;
    height:18vw;
  }
  .logo_container{
    width:90%;
  }
  .ant-menu{
    display:flex;
    flex-direction:column;
    border:none;
  }
  .nav{
    margin: 0 auto;
    justify-content:flex-start !important;
    margin-bottom:0 !important;
    border-top:none;
    display:flex;
    justify-content:space-between !important;
    margin:auto;
    padding-left: 2.7%;
    padding-right:4%;
    margin-right:0;
    border-bottom:2px solid #1dbc60;
    z-index:9999;
    height:auto;
  }
  body{
    border-top:none;
  }
  .iconita_drawer{
    font-size:12vw;
    text-align:center;
    display:flex;
    margin-right:0;
  }
}

@media only screen and (min-width:150px)and (max-width:767px){
  .logo_container p{
    font-size:4.4vw;
    line-height:5vw;
  }
}
@media only screen and (min-width:180px) and (max-width:767px){
  .logo_container p{
    font-size:4.45vw;
    line-height:5.1vw;
  }
}

@media only screen and (min-width:200px) and (max-width:767px) {
  .ant-drawer-content-wrapper{ 
    width:150px !important;
  }
  .ant-menu-item{
    font-size:0.45rem !important;
    margin-bottom:10px !important;
    padding:15px 5px !important;
  }
  .logo_container p{
    font-size:4.48vw;
    line-height:5.1vw;
  }
}

@media only screen and (min-width:320px) and (max-width:767px){
  .ant-drawer-content-wrapper{ 
    width:60vw !important;
  }
  .ant-drawer-header{
    padding-left:12% !important;
  }
  .ant-drawer-title{
    font-size:1.2rem;
  }
  .ant-menu-item{
    font-size:0.7rem !important;
  }
  .logo_container p{
    font-size:4.2vw;
    line-height:5vw;
  }
}

@media only screen and (min-width:480px)and (max-width:767px){
  .ant-menu-item{
    font-size:1rem !important;
    padding:20px 10px !important;
  }
}

@media only screen and (min-width:600px) and (max-width:767px){
  .ant-drawer-content-wrapper{ 
    width:70vw !important;
  }

  .ant-drawer-header{
    padding-left:10vw;
  }
  .ant-drawer-title{
    font-size:2.5rem;
  }
  .ant-drawer-header{
    padding:50px !important;
    padding-left:8vw !important;
  }
  .ant-menu-item{
    height:8vw !important;
    line-height:8vw !important;
    padding:0 !important;
    padding-left:5vw !important;
    font-size:1.2rem !important;
  }
}

@media only screen and (min-width:1080px){
  .logo_container p{
    font-size: 0.95vw !important;
    line-height:1.1vw !important;
  }
  .menu__logo{
    width:5vw;
    height:5vw;
  }
  .nav a{
    padding:0.2vw 0.9vw;
  }
}

/* ================Landscape mode================ */
/* ================Landscape mode================ */
/* ================Landscape mode================ */
/* ================Landscape mode================ */
/* ================Landscape mode================ */
/* ================Landscape mode================ */
/* ================Landscape mode================ */

@media only screen and (min-width:768px) and (max-width:1080px){
  .nav a{
    padding:0.2vw 0.9vw;
  }
}
.footer{
    color:white;
    background:black;
    left:0;
    right:0;
    display:flex;
    height:600px;
    flex-direction:column;
    position: absolute;
    bottom:0;
    font-size:0.8vw;
    overflow:hidden !important;
}
.footer h3{
    color:white;
}
.footer_links{
    width:80%;
    height:80%;
    background:black;
    display:flex;
    margin:auto;
}
.footer_links_inner_div{
    margin: auto;
    display:flex;
    justify-content: space-around;
    width:80%;
    margin:auto;
}
.copyright{
    background:#444444;
    width:100%;
    height:5vh;
    display:flex;
    font-size:0.9vw;
}
.copyright_text{
    margin:auto;
}
.despre{
    width:100%;
}
.linkuri_utile{
    display:flex;
    flex-direction:column;
    margin:0% 3%;
    margin-top:1.5vh !important;
    margin-left:5%;
}
.linkuri_utile h3{
    margin-bottom: 3vh;
}
.linkuri_utile a{
    color:white;
    text-decoration:none;
}
.informatii_contact{
    display:flex;
    flex-direction:column;
    margin:0% 3%;
    margin-top:1.5vh !important;
}
.informatii_contact a {
    text-decoration: none;
    color:white;
}
.informatii_contact h3{
    margin-bottom:3vh;
}
.facebook_like{
    margin-top:1.5vh !important;
    margin-right:0 !important;
}
.facebook_like h3{
    margin-bottom:3vh;
}
.facebook_like a{
    color:white;
}
.arrow{
    color:#1dbc60;
    font-size:0.8vw;
    display:inline;
    margin-right:6px;
}
.logo_footer img{
    width:60px;
    margin-left:6px;
}
.logo_footer{
    margin:auto;
}
.despre p{
    margin:0;
    padding:0;
}
.logo_si_despre{
    width:26%;
    display:flex;
    flex-direction:column;
    margin-left:0;
}

/* ==============media queries===================== */
/* ==============media queries===================== */
/* ==============media queries===================== */
/* ==============media queries===================== */
/* ==============media queries===================== */
/* ==============media queries===================== */
/* ==============media queries===================== */

@media only screen and (min-width:100px) and (max-width:767px){
    .footer{
        width:100%;
        display:flex;
        flex-direction:column;
        margin: 0 auto !important;
        height:28rem;
        overflow:hidden;
        font-size:4vw;
    }
    .footer_links{
        width:90vw;
        height:auto;
    }
    .footer_links_inner_div{
        width:100vw;
        display:flex;
        flex-direction:column;
        justify-content:center !important;
        justify-content:space-between !important;
        grid-gap:1.5vh;
        gap:1.5vh;
    }
    .link_util{
        font-size:6px;
        display:flex;
        vertical-align:middle;
        
    }
    .arrow{
        font-size:6px;
        vertical-align: middle;
        display:flex;
        margin-top:auto;
        margin-bottom:auto;
        margin-right:10px;
    }
    .logo_si_despre{
        width:100%;
        font-size:6px;
    }
    .logo_footer{
        margin-left:-6px;
        width:20vw;
    }
    .logo_footer img{
        width:20vw;
    }
    .linkuri_utile{
        width:100%;
        margin: auto;
    }
    .linkuri_utile h3{
        margin-bottom:2px;
        font-size:8px;
    }
    .informatii_contact{
        width:100%;
        margin:auto;
    }
    .informatii_contact h3{
        margin-bottom:2px;
        font-size:8px;
    }
    .facebook_like{
        width:100%;
        margin:auto;
    }
    .facebook_like h3{
        margin-bottom: 2px;
        font-size:8px;
    }
    .footer_link_text{
        margin-left:1.3vw;
    }
    .footer_link_email{
        margin-left:0.3vw;
    }
    .copyright{
        height:30px;
    }
    .copyright_text{
        font-size:4vw;
        font-size:8px;
    }
    #root{
        padding-bottom:500px;
    }
}

@media only screen and (min-width:150px) and (max-width:767px){
    .footer{
        height:25rem;
    }
}

@media only screen and (min-width:200px) and (max-width:767px){
    .footer{
        height:30rem;
    }
    .logo_si_despre{
        font-size:8px;
    }
    .linkuri_utile h3{
        font-size:10px;
    }
    .informatii_contact h3{
        font-size:10px;
    }
    .facebook_like h3{
        font-size:10px;
    }
    .arrow{
        font-size:8px;
    }
    .link_util{
        font-size:8px;
    }
}

@media only screen and (min-width:250px) and (max-width:767px){
    .footer_link_text{
        margin-left:0.7vw;
    }
}

@media only screen and (min-width:300px) and (max-width:767px){
    .footer{
        height:32rem;
    }
    .logo_si_despre{
        font-size:10px;
    }
    .linkuri_utile h3{
        font-size:12px;
    }
    .informatii_contact h3{
        font-size:12px;
    }
    .facebook_like h3{
        font-size:12px;
    }    
    .arrow{
        font-size:10px;
    }
    .link_util{
        font-size:10px;
    }
    .copyright_text{
        font-size:12px;
    }
    .copyright{
        padding:10px;
        height:50px;
    }
    #root{
        padding-bottom:600px;
    }
    .footer_link_text{
        margin-left:0.5vw;
    }
}

@media only screen and (min-width:350px) and (max-width:767px){
    .footer{
        height:40rem;
    }
    .logo_si_despre{
        font-size:12px;
    }
    .linkuri_utile h3{
        font-size:14px;
    }
    .informatii_contact h3{
        font-size:14px;
    }
    .facebook_like h3{
        font-size:14px;
    }    
    .arrow{
        font-size:12px;
    }
    .link_util{
        font-size:12px;
    }
    .copyright_text{
        font-size:14px;
    }
    .copyright{
        padding:12px;
        height:60px;
    }
    #root{
        padding-bottom:650px;
    }
    .footer_link_text{
        margin-left:0.4vw;
    }
}

@media only screen and (min-width:450px) and (max-width:767px){
    .footer{
        height:48rem;
    }
    .logo_si_despre{
        font-size:14px;
    }
    .linkuri_utile h3{
        font-size:16px;
    }
    .informatii_contact h3{
        font-size:16px;
    }
    .facebook_like h3{
        font-size:16px;
    }    
    .arrow{
        font-size:14px;
    }
    .link_util{
        font-size:14px;
    }
    .copyright_text{
        font-size:16px;
    }
    .copyright{
        padding:14px;
        height:60px;
    }
    #root{
        padding-bottom:800px;
    }
    .footer_link_text{
        margin-left:0.3vw;
    }
}

@media only screen and (min-width:550px) and (max-width:767px){
    .footer{
        height:60rem;
    }
    .logo_si_despre{
        font-size:16px;
        margin-bottom:20px;
    }
    .linkuri_utile h3{
        font-size:18px;
    }
    .informatii_contact h3{
        font-size:18px;
    }
    .facebook_like h3{
        font-size:18px;
    }
    .arrow{
        font-size:16px;
    }
    .link_util{
        font-size:16px;
    }
    .copyright_text{
        font-size:18px;
    }
    .copyright{
        padding:20px;
        height:70px;
    }
    #root{
        padding-bottom:1000px;
    }
    .footer_link_text{
        margin-left:0.2vw;
    }
    .linkuri_utile{
        margin-bottom:20px;
    }
    .informatii_contact{
        margin-bottom:20px;
    }
}

@media only screen and (min-width:550px) and (max-width:767px){
    .footer{
        height:70rem;
    }
    .logo_si_despre{
        font-size:20px;
        margin-bottom:20px;
    }
    
    .linkuri_utile h3{
        font-size:22px;
    }
    .informatii_contact h3{
        font-size:22px;
    }
    .facebook_like h3{
        font-size:22px;
    }
    
    .arrow{
        font-size:20px;
    }
    .link_util{
        font-size:20px;
    }

    .copyright_text{
        font-size:22px;
    }
    .copyright{
        padding:30px;
        height:100px;
    }
    #root{
        padding-bottom:1300px;
    }
    .linkuri_utile{
        margin-bottom:20px;
    }
    .informatii_contact{
        margin-bottom:20px;
    }
}

@media only screen and (min-width:768px) {
    .footer{
        height:30vw !important;
    }
    .link_util{
        display:flex;
    }
    .link_util div{
        margin-top:0;
        padding:0;
    }
    .svg-inline--fa{
        margin-top:auto;
        margin-bottom:auto;
    }
    .copyright{
        height:4vw !important;
    }
    .logo_footer{
        width:50px;
        height:50px;
    }
    .logo_footer img{
        width:50px;
        height:50px;
    }
}

@media only screen and (min-width:1080px){
    .footer{
        height:20vw !important;
    }
    .linkuri_utile h3{ 
        margin-bottom:20px;
    }
    .informatii_contact h3{
        margin-bottom:20px;
    }
    .facebook_like h3{
        margin-bottom:20px;
    }
    .copyright{
        height: 4vw;
    }
    .link_util{
        display:flex;
    }
    .arrow{
        margin-top:auto;
        margin-bottom:auto; 
    }
}

@media only screen and (min-width:2000px){
    #root{
        padding-bottom:800px;
        padding-bottom:30vw;
    }
}

/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */
/* ==================Landscape mode=================== */

@media only screen  and (max-width:767px) and (orientation:landscape){
    .footer{
        width:100%;
        display:flex;
        flex-direction:column;
        margin: 0 auto !important;
        height:100rem;
        overflow:hidden;
        font-size:4vw;
    }
    .footer_links{
        width:90vw;
        height:auto;
    }
    .footer_links_inner_div{
        width:100vw;
        display:flex;
        flex-direction:column;
        justify-content:center !important;
        justify-content:space-between !important;
        grid-gap:1.5vh;
        gap:1.5vh;
    }
    .link_util{
        font-size:24px;
        display:flex;
        vertical-align:middle;
    }
    .arrow{
        font-size:24px;
        vertical-align: middle;
        display:flex;
        margin-top:auto;
        margin-bottom:auto;
        margin-right:10px;
    }
    .logo_si_despre{
        width:100%;
        font-size:24px;
        margin-bottom:20px;
    }
    .logo_footer{
        margin-left:-6px;
        width:20vw;
    }
    .logo_footer img{
        width:20vw;
    }
    .linkuri_utile{
        width:100%;
        margin: auto;
        margin-bottom:20px;
    }
    .linkuri_utile h3{
        margin-bottom:2px;
        font-size:26px;
    }
    .informatii_contact{
        width:100%;
        margin:auto;
        margin-bottom:40px;
    }
    .informatii_contact h3{
        margin-bottom:2px;
        font-size:26px;
    }
    .facebook_like{
        width:100%;
        margin:auto;
        font-size:26px;
    }
    .facebook_like h3{
        margin-bottom: 2px;
        font-size:26px;
    }
    .footer_link_text{
        margin-left:1.3vw;
    }
    .footer_link_email{
        margin-left:0.3vw;
    }
    .copyright{
        padding:50px;
        height:150px;
    }
    .copyright_text{
        font-size:26px;
    }
    #root{
        padding-bottom:1900px;
    }
}

@media only screen and (min-width:768px) and (max-width:1079px) and (orientation:landscape){
    #root{
        padding-bottom:400px;
    }
    .footer{
        height:300px;
        font-size:1.2vw;
    }
    .footer_links{
        width:90%;
        height:80%;
        background:black;
        display:flex;
        margin:auto;
    }
    .footer_links_inner_div{
        margin: auto;
        display:flex;
        width:90%;
        margin:auto;
    }
    .copyright{
        height:10vh;
        font-size:1.2vw;
    }
    .arrow{
        color:#1dbc60;
        font-size:1.2vw;
        display:inline;
    }
    .logo_footer img{
        width:60px;
        margin-left:6px;
    }
    .logo_footer{
        margin:auto;
    }
    .despre p{
        margin:0;
        padding:0;
    }
    .logo_si_despre{
        width:40%;
        display:flex;
        flex-direction:column;
        margin-left:0;
    }
    .link_util{
        display:flex;
    }
    .link_util div{
        margin-top:0;
        padding:0;
    }
    .svg-inline--fa{
        margin-top:auto;
        margin-bottom:auto;
    }
}

@media only screen and (min-width:768px) and (orientation:landscape) {
    .footer{
        height:35vw !important;
    }
    .link_util{
        display:flex;
    }
    .link_util div{
        margin-top:0;
        padding:0;
    }
    .svg-inline--fa{
        margin-top:auto;
        margin-bottom:auto;
    }
    .copyright{
        height:4vw !important;
    }
}

@media only screen and (min-width: 1080px) and (orientation:landscape){
    .footer{
        height:25vw !important;
    }
}
.post_page{
    width:90%;
    margin:auto;
    border:1px solid #cdcdcd;
    margin-top:50px;
    background:white;
    /* max-height:5000px; */
    padding:20px;
}

.titlu_articol_post{
    color:grey;
    /* font-size:1.2vw; */
    /* display:inline-block; */
}
.detalii_volum_container_articol{
    width:70%;
    margin-left:0;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    margin-bottom:0;
}

.detalii_volum_container_articol_mic{
    width:100%;
    margin-left:0;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    margin-bottom:0;
    margin-top:auto;
    position:relative;
}

.detalii_volum_container_articol_mic >span, .detalii_volum_container_articol_mic  >div {
    font-size:20px;
    display:inline-block;
    padding-top:20px;
    margin-left:3%;
}
.casuta_post{
    width:90%;
    margin:auto;
}
.coperta_si_detalii_articol{
    display:flex;
}

.span_articol{
    position:absolute;
    left:300px;
    margin-left:auto;
}

.cuvinte_cheie{
    text-align:justify;
}
.detalii_articol{
    max-height:4000px;
    display:flex;
    flex-direction:column;
    grid-gap:10px;
    gap:10px;
    width:100%;
    /* min-height:100%!; */
    margin-top:20px;
    overflow:auto;
    position: relative;
    height: auto;
    

    /* min-height: 100% !important; */
}
.detalii_articol > div{
    /* position:relative; */
    font-size:20px;
    display:flex;
    min-height:100%;
    overflow:auto;
    height:auto;
    width:100%;
}

.first_div{
    width:31%;
}
.second_div{
    width:69%;
}
@media only screen and (min-width:150px) and (max-width:767px){
  /*pagina articol individual*/
  .post_page{
    max-width: 97%;
    }
  .coperta_si_detalii_articol{
    display:flex;
    flex-direction: column;
    max-width: 96% !important;
    /* position:relative; */
    }
  .coperta_si_detalii_articol > img{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width:50% !important; */
    width: 11rem;
    height: 15rem;
    /* width: 30vh;
    height: 25vh; */
  
   }
   .detalii_volum_container_articol{
    display: flex;
    align-items: center;
    }  
    .detalii_volum_container_articol_mic >span, .detalii_volum_container_articol_mic  >div {
        font-size:.8rem;
        display:inline-block;
        /* padding-top:20px; */
        margin-left:3%;
        /* align-items:flex-end; */
    }
    .detalii_articol > div{
        /* position:relative; */
        font-size:1rem;
        display:flex;
        min-height:100%;
        overflow:auto;
        height:auto;
        width:100%;
    }
    .span_articol {
        display: flex;
        align-items: flex-start;
        left: 10rem;
        position:-webkit-sticky;
        position:sticky;
        font-weight: 600;
        font-size: .8rem;
    
    }
    .titlu_articol_post{
        font-size: .9rem;
    }
    .casuta_post .revista_titlu{
        font-size: .8rem !important;
        margin: 0 !important;
    }
    .first_div{
        font-size: .8rem;
        margin-right: .5rem;
    }
    .second_div{
        font-size: .8rem;
      
    }
    .span_articol .pdf_icon{
       padding-top: 0 !important;
       display: flex;
       flex-direction: row;
      margin-top: .4rem;
        /* vertical-align:sub; */
        
    }
    .descarca_articol{
        font-size: .8rem;
        font-weight:200 !important;
        margin-left: 0 !important;
    }
    .detalii_de_la_server_articol{
        margin: 0 !important;
    }
}


.container_blog{
    display:flex;
    width: 97% !important;   
    /* flex-direction:row; */
}
/* .container_articole{
    width:70%;
    margin-right:0;
    margin-left:auto;
} */

.left_column{
    margin-left:0;
    margin-right:auto;
}
.pagination_div >ul {
    position:relative;
    display:flex;
    flex-direction:row !important;
    padding:0;
    overflow:auto;
    text-align:left;
    background: #fbfbfb;
    border-radius:5px;
    height:40px !important;
    margin-top:20px;
}


.pagination_div >ul li a{
    padding: 0px 3px;
    margin-top:auto;
    margin-bottom:auto;
}
.pagination_div >ul li a{
    color:#1dbc60;
}
.pagination_div >ul li a:hover{
    color:#1dbc60;
}

@media only screen and (min-width:150px) and (max-width:767px){
  .container_blog{
    display:flex;
    width:98%;
    margin: 0 auto;
    overflow:visible;
    flex-direction: column;
    overflow:hidden;
    /* display: flex; */
    /* justify-content: flex-end; */
  }
}



*{
    clear:both;
    box-sizing:border-box !important;
    font-family:'Quicksand', sans-serif;
}
#root{
    overflow:visible;
}
body{
    background:#fbfbfb;
}
::selection {
    background: #1dbc60; /* WebKit/Blink Browsers */
}
::-moz-selection {
    background: #1dbc60; /* Gecko Browsers */
}
.bigContainer{
    width:97%;
    margin:0 auto;
    padding-bottom:3.125rem;
    display:flex;
    flex-direction:column;
    /* overflow: auto; */
    
}
.smallContainer{
    display:flex;
    width:98%;
    overflow:visible;
}


.CardsContainer{
    width:98%;
    display:flex;
    flex-direction:column;
    overflow:hidden;
    cursor:auto;
}
.left_column{
    margin-left:auto;
    padding:20px 20px;
    padding-bottom:33px;
    width: 30%;
    align-self:flex-start;
    background-color:white;
    border:1px solid #f1f1f1;
    position: -webkit-sticky !important;
    position: sticky !important;
    top:0;
}
.col{
    display:inline-block;
    width: 103%;
    overflow:hidden;
    display: flex;
    align-items: center;
    margin-left:1% !important;
}

.pagination{
    position:relative;
    display:flex;
    flex-direction:column;
    padding:0;
    overflow:auto;
    text-align:left;
    height:10rem;
    background: #fbfbfb;
    border-radius:5px;
    padding:10px;
  
}

.pagination li{
    display:flex;
    font-size:18px;
    width:90%;
    text-align:left;
    margin:left;
    cursor:pointer;    
}
.pagination li:hover{
    color:#1dbc60;
    cursor:pointer;
}
ul{
    display:flex;
    flex-direction:row;
}
.pagination_container{
    width:100%;
}
.titlu_articol{
    text-decoration:none;
    color:black;
    width:78%;
    display:inline;
}
.titlu_articol:hover{
    color:#1dbc60 !important;
}
.continut_card> a> img{
    width:25rem;
    height:31.25rem;
    margin-bottom:0;
    margin-top: 1rem;
    vertical-align:bottom;
    bottom:0;
}
.coperti{
    width:25rem;
    height:31.25rem;
 
}

.logo{
    width: 6.25rem;
    height: 6.25rem;
    background-image:url(/static/media/logo100.f3609a84.png);
    background-position:center;
    background-repeat:no-repeat;
    margin:0;
    padding:0;
    border:none;  
}







.view_all{
    color: #fbfbfb;
    padding: 5px 8px;
    background:#1dbc60;
    font-size:20px;
    outline:none;
    border:1px solid #1dbc60;
    border-radius:5px;
    font-weight:bold;
    cursor:pointer;
}
.view_all:hover{
    color:#1dbc60;
    background:#fbfbfb;
}
.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}
.checkbox > input {
    height: 40px;
    width: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 5px;
    outline: none;
    transition-duration: 0.3s;
    background-color: #fbfbfb;
    cursor: pointer;
    margin-right:10px;
    margin-top:auto;
    margin-bottom:auto;
}
.checkbox > input:checked {
    background-color: #f3f3f3;
}
.checkbox > input:checked + button::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #1dbc60;
    position: absolute;
    left:0.2rem;
    bottom:-0.25rem;
    zoom:2;
}
.view_all_si_checkbox{
    display:flex;
    vertical-align:middle;

}






.view_all_authors{
    color: #fbfbfb;
    padding: 5px 8px;
    background:#1dbc60;
    font-size:20px;
    outline:none;
    border:1px solid #1dbc60;
    border-radius:5px;
    font-weight:bold;
    cursor:pointer;
}
.view_all_authors:hover{
    color:#1dbc60;
    background:#fbfbfb;
}

#view_all_authors{
    height:40px;
    width:40px;
}


.linie_sub_arhiva{
    width:100%;
    margin-bottom:50px;
    margin-top:10px;
}
.sageti{
    width:0px;
    height:0px;
    position:absolute;
    top:0;
}
.icon_arhiva{
    font-size:40px;
    color:#1dbc60;
    margin-top:35px;
    margin-right:20px;
}
.display_articole_button{
    background:#1dbc60;
    border:none;
    border-radius:5px;
    color:#fbfbfb;
    border: 2px outset #1dbc60;
    outline:none;
    font-weight:bold;
    padding:5px 8px;
}
.display_articole_button:hover{
    cursor:pointer;
    background:#fbfbfb;
    color:#1dbc60;
    border: 2px outset #1dbc60;
}
.volume{
    display:block;
    font-size:1.125rem;
    font-weight:bold;
    color:#1dbc60;
    margin-left:3%;
}
.autori_titlu{
    margin-top:0.625rem;
    display:block;
    font-size:18px;
    font-weight:bold;
    color:#1dbc60;
    margin-left:3%;
}
.despreContainer{
    border:1px solid lightgrey;
    overflow:hidden;
    position:relative;
    width:98%;
    font-size:1.125rem;
    margin:0 auto;
    display:flex;
    flex-direction:row;
    background-image: url(/static/media/arhiva_background.7e2575ba.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color:#fbfbfb;
    position:relative;
    border:1px solid #f1f1f1;
    margin-left:1%;
}
.despreOverlay{
    position:absolute;
    min-width:100%;
    max-width:100%;
    min-height:100%;
    max-height:100%;
    overflow:hidden;
    display:flex;
    background-color:rgba(170, 96, 96, 0.5);
    z-index:10;
}
.despre_pontica{
    width:100%;
    margin:auto;
    padding:0.938rem 0.938rem;
    z-index:100;
}
.descarca{
    margin-right:100px;
    display:inline-block;
}
.divarticol{
    display:flex;
    flex-direction:row;
    width:100%;
    font-size:18px;
}
.divarticol_gri{
    background:#fbfbfb;
    display:flex;
    flex-direction:row;
    width:100%;
    margin-top:10px;
    margin-bottom:10px;
    border-top:1px solid #cdcdcd;
    border-bottom:1px solid #cdcdcd;
    font-size:18px;
}

.autori{
    width:18%;
    margin-left:4%;
    font-size:18px;
}
.autor_link{
    color:inherit;
}
.autori_link{
    display:block;
}
.container_autor{
    display:flex;
    flex-direction:column;
}
.container_autor :hover{
    background-color:#1dbc60;
    color:white;
    transition: all 0s ease-in-out;
}
.divTitlu{
    padding:20px 0px;
    background:#fbfbfb;
    display:flex;
    flex-direction:row;
    width:100%;
    margin-top:10px;
    margin-bottom:10px;
    border-top:1px solid #cdcdcd;
    border-bottom:1px solid #cdcdcd;
    font-weight:bold;
    font-size:22px;
}
.div_titlu_header_articol{
    width:78%;
}
.div_titlu_header_autori{
    width:18%;
    margin-left:4%;
}
.gri{
    display:flex;
    flex-direction:row;
    width:100%;
    background:#fbfbfb;
    vertical-align:middle;
    width:94%;
    margin-left:auto;
    margin-right:0;
    padding:10px 0px;
}

.alb{
    display:flex;
    flex-direction:row;
    width:100%;
    background:white;
    vertical-align:middle;
    width:94%;
    margin-left:auto;
    margin-right:0;
    padding:10px 0px;
}
.display_search_results{
    height:auto;
    width:auto;
    margin:left;
    overflow:auto;
}
.display_search_results span{
    margin-right:10px;
    display:inline-block;
}
.search_button{
    padding:0px 5px;
    background:#1dbc60;
    font-size:18px;
    color:#fbfbfb;
    font-weight:bold;
    cursor:pointer;
    border:none;
    border: 2px outset #1dbc60;
    border-radius:5px;
    margin:auto;
    margin-left:0;
    margin-top:15px;
    outline:none;
}
.search_button:hover{
    background:#fbfbfb;
    color:#1dbc60;
    border: 2px outset #1dbc60;

}
.search_button:hover a{
    color:#1dbc60;
}
.search_input{
    width:130px;
    border-radius:5px;
    outline:none;
    border:1px solid #1dbc60;
    height:31px;
    background:#fbfbfb;
    margin:auto;
    margin-left:-20px;
    margin-top:15px;
    font-size:20px;
    color:#1dbc60;
}
.search_input:focus{
    background: #f4fdf4;
    box-shadow: 0 0 0 1px #1dbc60;
    border-radius: 5px;
}
.search{
    display:flex;
    vertical-align:middle;
}
.iconite_descriere_arhiva{
    margin-right:30px;
    margin-bottom:20px;
}
.epontica_links_container{
    display: flex;
  width: 95%;
  justify-content: center;
}

    
.linkuri_descriere_arhiva{
    color:#1dbc60;
    font-weight:bold;
}
.linkuri_descriere_arhiva:hover{
    color:white;
}
.arhiva_header{
    width:100%;
    margin:auto;
    vertical-align:middle;
    margin-bottom:1.563rem;
}
.iconita_arhiva{
    font-size:1.9rem;
    margin-top:auto;
    margin-bottom:auto;
    margin-left:auto;
}

.arhiva_header h1{
    font-size:2rem;
    display:inline-block;
    margin:auto;
    margin-top:auto;
    margin-bottom:auto;
    margin-left:10px; /*distanteaza iconita de scris*/
}
.logo_and_title{
    margin:auto;
    margin-bottom: 1rem; 
    text-align:center;
    display:flex;
    width:97%;
    justify-content:center;
}
.continut_card{
    display:flex;
    flex-direction:row;
    margin-top:auto;
    position:relative;
}
.detalii_volum_container{
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:68%;
    grid-gap:0;
    gap:0;
    margin:auto;
    margin-right:0;
    position:relative;
    margin-bottom:0;
}
.detalii_volum{
    display:inline-block;
    font-size:1.25rem;
    width:94%;
    margin-left:auto;
    margin-right:0;
}
.card{
    overflow:visible;
    font-size:1.25rem;
    padding:0rem 0.625rem;
    cursor:auto;
    width:95%;
    border:1px solid #f1f1f1;
    margin-top:1%;
}
.casuta{
    overflow:hidden !important;
    top:-15px;
    margin:0 !important;
}
.casutavolume{
    position:relative;
    display:flex;
    flex-direction:column;
    padding:0;
    overflow:auto;
    height:10rem;
    background: #fbfbfb;
    border-radius:0.313;
    padding:0.625rem;
    margin-bottom:.6rem;
}
.span_de_la_server{
    position:absolute;
    left:360px;
    margin-left:auto;
}
.display_none{
    position:absolute;
    top:0;
    display:none;
}
.paginatie_an{
    margin-right:0;
    display:inline-block;
    margin:auto;
    position:absolute;
    right:0;
    text-align:left;
    width:50%;
}
.lista_toti_autorii{
    position:relative;
    display:flex;
    flex-direction:column;
    padding:0;
    overflow:auto;
    text-align:left;
    height:10rem;
    background: #fbfbfb;
    border-radius:5px;
    padding:10px;
    
}
.lista_toti_autorii a{
    font-size:18px;
}
.lista_toti_autorii a:hover{
    color:#1dbc60;
    cursor:pointer;
}



.active{
    color:#1dbc60;
    font-weight:bold;
}


.volume_selectate span, .autori_selectati span{
    font-weight:bold;
    font-size:18px;
    color:#1dbc60;
}
.autori_selectati_titlu, .volume_selectate_titlu{
    font-weight:bold;
    font-size: 18px;
    color:#1dbc60;
    margin-left:3%;
}
.volume_selectate, .autori_selectati{
    padding-left:3%;
    background:#fbfbfb;
    overflow:auto;
    max-height:7rem;
    border-radius:5px;
    padding:10px;
}
.volume_selectate p, .autori_selectati p{
    margin-bottom:0;
}





.volum, .autor{
    margin-bottom:5px;
    margin-top:auto;
    margin-bottom:auto;
}

.volum:hover, .autor:hover{
    color:#1dbc60;
    cursor:pointer;
}


.check_volum, .check_autor{
    color: #fbfbfb;
    padding: 5px 8px;
    background:#1dbc60;
    font-size:20px;
    outline:none;
    border:1px solid #1dbc60;
    border-radius:5px;
    font-weight:bold;
    cursor:pointer;
    margin-top:auto;
    margin-bottom:auto;
    margin-right:3%;
}


.filtreaza{
    padding:0px 5px;
    background:#1dbc60;
    font-size:18px;
    color:#fbfbfb;
    font-weight:bold;
    cursor:pointer;
    border:none;
    border: 2px outset #1dbc60;
    border-radius:5px;
    margin:auto;
    margin-left:0;
    margin-top:15px;
    outline:none;
}

.filtreaza:hover{
    background:#fbfbfb;
    color:#1dbc60;
    border: 2px outset #1dbc60;
}


.articole_filtrate_container{
    background:white;
    display:flex;
    font-size:1.125rem;
   
}

.articole_filtrate_container_gri{
    background:#fbfbfb;
    display:flex;
    font-size:1.125rem;
   
}

.casuta_afisare_filtru{
    width:95%;
   
}


.numar_articol_filtrat{
    padding:.5rem;
    color:black;
    font-weight:bold;
    margin-left:0;
    margin-right:auto;
}

.numar_articol_filtrat:hover{
    color:#1dbc60;
}

.titlu_volum_filtrat{
    width:20%;
    padding:5px;
    color:black;
    margin-right:0.4rem;
    margin-left: 0.313rem;
}

.titlu_articol_filtrat{
    width:50%;
    padding:5px;
    color:black;
    margin-right:0;
    margin-left: 0 !important;
    display:inline;

}
.titlu_articol_filtrat a{
    text-decoration:none;
    color:inherit;
    
}
.titlu_articol_filtrat a:hover{
    color:#1dbc60;
}

.autori_articol_filtrat{
    width:19%;
    padding:5px;
    margin-right:0;
    margin-left:auto;
}

.container_autor_filtrat{
    display:flex;
    flex-direction:column;
    padding:5px;

}
.container_autor_filtrat :hover{
    background-color:#1dbc60;
    color:white;
    transition: all 0s ease-in-out;
}


.search_link{
    color:white;
}
.search_link:hover{
    color:#1dbc60;
}

.revista_titlu{
    margin-top: .5rem;
    font-size:1.5vw;
}


@media only screen and (min-width:100px) and (max-width:767px){
    .bigContainer{
        max-width:96%;
        margin-top: 1rem !important;
    }
   
    .CardsContainer{
        max-width:98%;
        margin-left: .3rem;
    }
    .separator{
        width:98%;
        display: flex;
    }
    .linie >h1 { /*titlu*/
        color:black;
        font-size:2.6vw;
        max-width:96%;
    }
    .logo_and_title{
        max-width:98%;
        
    }
    .titlu_arhiva{
        width:98%;
    }
  
    .arhiva_header{
        width:100%;
        margin:auto;
        vertical-align:middle;
        margin-bottom:0;
    }
  
   
    .despre_pontica > a > img{
       
        position: relative;
    }
    
   .continut_card> a> img{
    width:10rem;
    height:15rem;
    margin-bottom:0;
    }
    
    .detalii_volum_container{
        height:14.5rem;
    }
    .alb {
        display: flex;
        font-size: .6rem;
        padding: 0 0 0 0 !important;
    }
    .gri{
        font-size: .6rem;
    }

   .smallContainer{
    display:flex;
    width:98%;
    margin: 0 auto;
    overflow:visible;
    }

    .revista_titlu{
        font-size:3vw;
    }
    .continut_card{
        display:flex;
        width: 97% !important;
        position:relative;
    }

    .ant-card-body{
    padding: 0.313rem 0.625rem !important;
    }
    .ant-card-hoverable{
        max-width: 97%;
    }
    
   
    
    /*toate articolele => detalii autor */
  
   
}
    
   


@media only screen and (min-width:150px) and (max-width:767px){
    .bigContainer{
        max-width:96%;
        margin-top: 1rem !important;
    }
    .smallContainer{
        display:flex;
        width:98%;
        margin: 0 auto;
        overflow:visible;
        flex-direction: column;
        overflow:hidden;
    }
    .CardsContainer{
        max-width:98%;
    }
    .logo_and_title{
        max-width:98%;
    }
    .linie >h1 {  /*titlu*/
        color: black;
        font-size:1rem;
        max-width:96%;
        margin-top: .5rem;
    }
    span{
        margin-left: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .arhiva_header{
        width:97%;
        display: flex;
        flex-direction: row;
        margin-bottom:0;
        align-items: center;
    }
    
    .despre_pontica > p {
        font-size: 4vw;
    };
   
    .despre_pontica > a > img{
    
       position: relative;
    }
   
    .revista_titlu{
        font-size:3vw;
    }
    .display_articole_button{
        background:#1dbc60;
        border:none;
        border-radius:5px;
        color:#fbfbfb;
        border: 2px outset #1dbc60;
        outline:none;
        font-weight:bold;
        padding:5px 8px;
        font-size: 12px !important;
    }
    .span_de_la_server{
        left: 100px;
        margin: 0 !important;
        font-size: 10px !important;
        padding: 0px 0px 15px;
       
    }
    .pdf_icon{
        padding-top: 5px;
       
    }
    
    .numar_articol_filtrat{
        padding:5px;
        color:black;
        font-weight:bold;
        font-size: 3.5vw !important;
    }
    .articole_filtrate_container{
        background:white;
        grid-gap:3%;
        gap:3%;
        font-size:0.5rem;
    }
      
    .articole_filtrate_container_gri{
        background:#fbfbfb;
        grid-gap:2%;
        gap:2%;
        font-size:0.5rem;
      
        
    }
    .titlu_volum_filtrat{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        color:black;
        font-size: 3.5vw !important;
    }
    .titlu_articol_filtrat{
        width:43% !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        color:black;
        margin-right:0;
        margin-left:.4rem;
       
        font-size: 3.5vw !important;
      
    }
    .autori_articol_filtrat{
      
        display: flex;
        flex-direction: column;
        justify-content:start;
        margin-right:0.625rem;
        font-size: 3.5vw !important;
    }

    .divTitlu{
        padding:0 !important;
        background:#fbfbfb;
        display:flex;
        flex-direction:row;
        width:100%;
        font-weight:bold;
        font-size:.8rem;
    }
    .divarticol{
        display:flex;
        flex-direction:row;
        width:100%;
        font-size:.7rem;
    }

    .divarticol span{
        font-size:.7rem;
    }

    .divarticol_gri{
        background:#fbfbfb;
        display:flex;
        flex-direction:row;
        width:100%;
        margin-top:10px;
        margin-bottom:10px;
        border-top:1px solid #cdcdcd;
        border-bottom:1px solid #cdcdcd;
        font-size:.7rem;
    }

    .divarticol_gri span{
        font-size:.7rem;
    }

    .container_autor{
        display:flex;
        flex-direction:column;
        font-size:.7rem;
    }
    .autori{
        font-size:.7rem;
        display: flex;
        flex-direction: column;
        justify-content:start;
        margin-right: 1rem;
    }
  
        
    .wrapper h2 {
        font-size: 4vw !important;
    }
    .wrapper p {
        font-size: 3vw !important;
    }
  
    .epontica_links_container{
        
        justify-content: center;
        width: 97% !important;
        margin-left: 0 !important;
        
    }
    .epontica_links_container > a> img{
        width: 97% !important;
    }

    .iconite_descriere_arhiva{
        width: 40%;
      
    }
    .left_column{
      padding: 0 !important;
      padding-bottom: 0 !important;
      width: 90%;
      flex-direction: column;
      background-color:white;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    
    .left_column .bigContainer{
        display: flex; 
        justify-content: flex-start; 
        position:-webkit-sticky; 
        position:sticky;
       
    }
    
    .volume{
        display:block;
        font-size:.9rem;
        font-weight:bold;
        color:#1dbc60;
        margin-left:3%;
        margin-bottom: .3rem;
    }
    .casutavolume{
        position:relative;
        display:flex;
        flex-direction:column;
        padding:0;
        overflow:auto;
        text-align:left;
        height:7rem;
        background: #fbfbfb;
        border-radius:0.313;
        padding:0.625rem;
    }
    .lista_toti_autorii{
        position:relative;
        display:flex;
        flex-direction:column;
        overflow:auto;
        text-align:left;
        height:7rem;
        background: #fbfbfb;
        border-radius:5px;
        padding:0.625rem;
        margin-bottom: .8rem;
        margin-top: .3rem;
    }
    .autori_titlu{
     
        display:block;
        font-size:.9rem;
        font-weight:bold;
        color:#1dbc60;
        margin-left:3%;
    }
    .volume_selectate_titlu{
        margin-top: .3rem;
        font-size: .9rem;
    }
    .autori_selectati_titlu{
        font-size: .9rem;
    }
    .filtreaza{
        margin-top: -.5rem;
        font-size: .8rem;
    }
    .search_button{
        font-size: .8rem;
    }
    .search_input{
        width: 40%;
        margin-bottom: 6px;
    }
    .iconita_arhiva{
        font-size:1rem;
        margin-top:auto;
        margin-bottom:auto;
        margin-left:auto;
    }
    .ant-card-body{
      
        padding: 0.313rem 0.625rem !important;
        max-width: 97%;
    }
    .ant-card-hoverable{
            max-width: 97%;
    }
    .casuta{
      
        max-width: 97%;
    }
    .continut_card{
        display:flex;
        flex-direction: row;
        max-width: 97% !important;
        position:relative;
       
    }
    .continut_card> a> img{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:97%;

    }
    
    .detalii_volum_container{
       width: 50%;
       margin-left: 0 !important;
    }
    .detalii_volum{
        position:relative;
        display:flex;
        flex-direction:column;
        align-items: flex-start;
    }
  
}


#toolbar{
    display:flex;
    flex-direction:row;
    /* border:2px solid red !important; */
}


.editare_articol_container{
    display:flex;
    flex-direction:column;
    max-height:auto;
    border:1px solid #cdcdcd;
    border-radius:5px;
    background:white;
    grid-gap:50px;
    gap:50px;
    padding:70px;
    width:60vw;
    margin:auto;
    position:relative;
}
.editare_articol_container>div{
    display:flex;
}
.editare_articol_container >div>input:focus,textarea:focus{
    border-radius:0;
    display: inline-block;
    box-shadow: 0 0 0 1px #1dbc60;
    border-radius: 5px;
    background:#f4fdf4;
}




.editare_articol_container >div>input, textarea{
    outline:none;
    border:1px solid #1dbc60;
    color:grey;
    border-radius:5px;
    width:20vw;
    color:grey;
    text-align:center;
    font-size:16px;
    background:#fbfbfb;
}

.editare_articol_container >div>label{
    color:grey;
    font-weight:bold;
    width:10vw;
}

.editare_articol_container>div>input,textarea{
    /* margin-right:20vw; */
    /* margin-left:5vw; */
}

.editare_articol_container >div>label{
    font-size:20px;
    vertical-align: middle;
    padding:0;
    display:flex;
    /* margin-top:auto;
    margin-bottom:auto; */
}

.editare_submit{
    border:1px solid #1dbc60;
    background:#1dbc60;
    padding:8px 15px;
    font-size:30px;
    border-radius:5px;
    color:white;
    width:20vw;
    margin-left:10vw;
}

.editare_submit:hover{
    background:white;
    color:#1dbc60;
    cursor:pointer;
}

.mesaj_confirmare{
    width:30vw;
    margin:auto;
    font-size:20px;
    display:flex;
    grid-gap:30px;
    gap:30px;
}
.mesaj_confirmare p{
    margin-top:auto;
    margin-bottom:auto;
}

.criteriu_de_cautare{
    margin-right:0;
    margin-left:auto;
}


.adaugare_autor_nou{
    display:flex;
    flex-direction:column;
    max-height:300px;
    grid-gap:10px;
    gap:10px;
    padding:5px;
    width:100%;
    margin:auto;
    position:relative;
    margin-top:100px;
}

.adaugare_autor_nou_input{
    outline:none;
    border:1px solid #1dbc60;
    color:grey;
    border-radius:5px;
    width:20vw;
    text-align:center;
    font-size:16px;
    background:#fbfbfb;
}
.adaugare_autor_nou_input:focus{
    display: inline-block;
    box-shadow: 0 0 0 1px #1dbc60;
    border-radius: 5px;
    background:#f4fdf4;
}
.submit_autor_nou{
    width:200px;
    margin-right:20px;
    background:#1dbc60;
    color:white;
    padding:5px 10px;
    font-size:20px;
    border:none;
    border-radius: 5px;
    border:1px solid #1dbc60;
}

.submit_autor_nou:hover{
    color:#1dbc60;
    background:white;
    cursor:pointer;
    border:1px solid #1dbc60;
}

.autor_nou_buton_si_input{
    display:flex;
    width:100%;
    margin:0 auto;
    margin-top:10px;
}

.submit_raspuns{
    background:#1dbc60;
    color:white;
    border:1px solid #1dbc60;
    border-radius:5px;
    cursor:pointer;
    padding:5px 10px;
    margin:auto;
}

.submit_raspuns:hover{
    background:white;
    color:#1dbc60;
}

.titlu_mare_editare_articol{
    margin:20px auto;
    width:20%;
    text-align:center;
}

.submit_text_articol_div{
    margin-top:100px;
    width:100%;
    display:flex;
    flex-direction:column;
}
.text_articol_div{
    display:flex;
}
#text_articol_textarea{
    width:80%;
    padding:10px;
}

.text_articol_label{
    font-size:20px !important;
    font-weight:bold;
    color:grey;
    margin-top:auto;
    margin-bottom:auto;
    margin-right:20px;
}

.submit_text_articol_button{
    margin-top:20px;
    font-size:20px;
    padding:5px 10px;
}
.edit_autor_container{
    border:1px solid red;
    width:50%;
    margin:auto;
}




.autor_heading{
    margin-top:100px;
    /* padding-top:100px; */
    text-align:center;
}
.pagina_autor_container{
    /* border:1px solid red; */
    width:80%;
    margin:auto;
    background-color:#fbfbfb;

    width:75%;
    margin:0 auto;
    margin-top:50px;
    font-size:18px;
    /* font-family:Verdana; */
    font-weight:500;
    /* border: 2px solid lightgrey; */
    padding:50px 150px;
    /* margin-bottom:200px; */
    /* box-shadow: 3px 3px 3px lightgoldenrodyellow; */
    /* box-shadow: 3px 3px 3px lightgrey; */
    /* box-shadow:4px 9px   lightgrey; */
    /* box-shadow: 10px 10px 8px #888888; */
    /* background:lightgoldenrodyellow; */
    background:#fbfbfb;
    border-radius:10px;


    /* -webkit-box-shadow: 0 0 10px 10px lightgrey;
    -moz-box-shadow: 0 0 10px 10px lightgrey;
    box-shadow: 0 0 10px 10px lightgrey; */


    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.separator_articole_autor{
    width:97%;
 
}


/* .underline_container_recenzare{
    width:40%;
    
} */
@media only screen and (min-width:150px) and (max-width:767px){
    .separator_articole_autor span{
        /* font-size: 1.5rem;
        text-align: end; */
        display: none;
    }
}



.editare_autori_container{
    width:30%;
    margin:auto;
    background:white;
    border:1px solid #cdcdcd;
    border-radius:5px;
    max-height:600px;
    overflow:auto;
    padding:20px;
}

.casuta_editare_autori{
    position:relative;
}
.editare_autori_titlu{
    width:30%;
    margin:auto;
}
.numar_autor{
    font-weight:bold;
}
.link_editare_autor{
    position:absolute;
    left:50px;
}

.link_editare_autor:hover span{
    color:#1dbc60;
}

.display_search_results{
    background:white;
    padding:0;
    margin:0;
    padding-top:30px;
    padding-bottom:5%;
    border: 1px solid #f1f1f1;
    overflow:visible;
    overflow-wrap:normal;
    margin-left:4%;
    width:80%;
}
.element_curent_div{
    margin-bottom:20px;
    width:100%;
}
.element_curent_div_gri{
    background:#fbfbfb;
    margin-bottom:10px;
    width:100%;
}
.element_curent{
    font-size:18px;
    padding:2px;
    padding-left:10px;

}
.element_curent_titlu{
    font-style:italic;
}
.element_curent_div:hover{
    color:#1dbc60;
}

.element_curent_div_gri:hover span{
    color:#1dbc60 !important;
    cursor:pointer;
}
.element_curent_div:hover span{
    color:#1dbc60 !important;
    cursor:pointer
}
.mergi_inapoi{
    background:#1dbc60;
    color:white;
    font-weight:bold;
    border-radius:5px;
    padding:5px 8px;
    font-size:20px;
    cursor:pointer;
    border:1px solid  #1dbc60;
    outline:none;
    display:inline-block;
    /* margin-right:0; */
    height:0%;
}

.mergi_inapoi:hover{
    color:#1dbc60;
    background:white;
}


.titlu_rezultate_cautare {
    font-size:20px;
    font-weight:bold;
    display:inline-block;
    background:#fbfbfb;
    padding:5px 8px;
    border-radius:5px;
}
.pagination_search_container{
    margin-left:auto;
}


.col_search{
    padding:0;
    margin:0;
    width:90%;
}



.logo_and_title_search{
    width:20%;
    margin:auto;
    display:flex;
    flex-direction:row;
    margin-top:30px;
}
.separator_search{
    margin:auto;
}

.underline_search{
}

.sageti_search{
    display:none;
    position:absolute;
    top:0;
}
.left_search{
    /* height:500px; */
    height:auto !important;
}
.pagination_search_container ul{
    height:auto;
    min-height:50px;
}

.titlu_rezultate{
    margin-left: 50px;
    background:#f1f1f1;
    display:inline-block;
    padding:2px 6px;
}


.admin_buttons a {
    color:#1dbc60;
    font-weight:bold;
    border:1px solid #cdcdcd;
    margin:20px;
    /* margin-top:100px; */
    display:inline-block;
    padding:20px;
    background:white;
}
.admin_buttons a:hover{
    background:#1dbc60;
    color:white;
}


.meniu_admin{
    height:100px;
    display:flex;
    width:100%;
    margin:auto;
    margin-top:100px;
    /* background:white; */
}
.meniu_admin_subdiv{
    display:flex;
    margin:auto;
}



