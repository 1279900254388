.post_page{
    width:90%;
    margin:auto;
    border:1px solid #cdcdcd;
    margin-top:50px;
    background:white;
    /* max-height:5000px; */
    padding:20px;
}

.titlu_articol_post{
    color:grey;
    /* font-size:1.2vw; */
    /* display:inline-block; */
}
.detalii_volum_container_articol{
    width:70%;
    margin-left:0;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    margin-bottom:0;
}

.detalii_volum_container_articol_mic{
    width:100%;
    margin-left:0;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    margin-bottom:0;
    margin-top:auto;
    position:relative;
}

.detalii_volum_container_articol_mic >span, .detalii_volum_container_articol_mic  >div {
    font-size:20px;
    display:inline-block;
    padding-top:20px;
    margin-left:3%;
}
.casuta_post{
    width:90%;
    margin:auto;
}
.coperta_si_detalii_articol{
    display:flex;
}

.span_articol{
    position:absolute;
    left:300px;
    margin-left:auto;
}

.cuvinte_cheie{
    text-align:justify;
}
.detalii_articol{
    max-height:4000px;
    display:flex;
    flex-direction:column;
    gap:10px;
    width:100%;
    /* min-height:100%!; */
    margin-top:20px;
    overflow:auto;
    position: relative;
    height: auto;
    

    /* min-height: 100% !important; */
}
.detalii_articol > div{
    /* position:relative; */
    font-size:20px;
    display:flex;
    min-height:100%;
    overflow:auto;
    height:auto;
    width:100%;
}

.first_div{
    width:31%;
}
.second_div{
    width:69%;
}
@media only screen and (min-width:150px) and (max-width:767px){
  /*pagina articol individual*/
  .post_page{
    max-width: 97%;
    }
  .coperta_si_detalii_articol{
    display:flex;
    flex-direction: column;
    max-width: 96% !important;
    /* position:relative; */
    }
  .coperta_si_detalii_articol > img{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width:50% !important; */
    width: 11rem;
    height: 15rem;
    /* width: 30vh;
    height: 25vh; */
  
   }
   .detalii_volum_container_articol{
    display: flex;
    align-items: center;
    }  
    .detalii_volum_container_articol_mic >span, .detalii_volum_container_articol_mic  >div {
        font-size:.8rem;
        display:inline-block;
        /* padding-top:20px; */
        margin-left:3%;
        /* align-items:flex-end; */
    }
    .detalii_articol > div{
        /* position:relative; */
        font-size:1rem;
        display:flex;
        min-height:100%;
        overflow:auto;
        height:auto;
        width:100%;
    }
    .span_articol {
        display: flex;
        align-items: flex-start;
        left: 10rem;
        position:sticky;
        font-weight: 600;
        font-size: .8rem;
    
    }
    .titlu_articol_post{
        font-size: .9rem;
    }
    .casuta_post .revista_titlu{
        font-size: .8rem !important;
        margin: 0 !important;
    }
    .first_div{
        font-size: .8rem;
        margin-right: .5rem;
    }
    .second_div{
        font-size: .8rem;
      
    }
    .span_articol .pdf_icon{
       padding-top: 0 !important;
       display: flex;
       flex-direction: row;
      margin-top: .4rem;
        /* vertical-align:sub; */
        
    }
    .descarca_articol{
        font-size: .8rem;
        font-weight:200 !important;
        margin-left: 0 !important;
    }
    .detalii_de_la_server_articol{
        margin: 0 !important;
    }
}

