.wrapper{
  width:97%;
  margin:0 auto;
  margin-top:50px;
  font-size:18px;
  font-family: 'Quicksand', sans-serif;
  font-weight:500;
  padding:50px 50px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px, rgba(0, 0, 0, 0.23) 0px 2px 2px;
  background:white;
  border-radius:5px;
}


.wrapper h2{
  text-align :center;
  padding-bottom:10px;
  /* padding-left: 5vw; */
}
.wrapper h3{
  margin-top: 1.5vw;
}


li{
  list-style-type: none;
}
.underline{
  width:42%;
  display:inline-block;
  text-align:center;
  vertical-align:middle;
  border-bottom:2px solid #1dbc60;
  margin-left:2%;
  margin-right:2%;

}
.underline_container{
  margin:0 auto;
  width:42%;
  margin-bottom:30px;
  margin-top:-20px;
}
span{
  color:grey;
  font-size:20px;
}
.icon_check{
  color:#1dbc60;
  margin-right:10px;
}
.descarca_sablon{
  font-weight:bold;
  /* border:1px solid #1dbc60; */
  border: 2px outset #1dbc60;
  outline:none;
  padding:8px 12px;
  font-size:20px;
  border-radius:5px;
  background:#1dbc60;
  color:white;
}
.descarca_sablon:hover{
  background:white;
  color: #1dbc60;
  cursor:pointer;
  border: 2px outset #1dbc60;
}





/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */
/* ================media queries=================== */



@media only screen and (min-width:100px) and (max-width:767px){
  .wrapper{
    width:99%;
    font-size:4vw;
    padding:5%;
    border-radius:1px;
    margin-top:0;
    box-shadow:none;
  }
  .descarca_sablon{
    font-size:4vw;
    padding:1vw 2vw ;
    border-radius:1px;
  }

  .underline_container{
    display:none;
  }
  .underline_recenzare{
    display:none !important;
  }
  .steluta{
    display:none;
  }
}


/* ================landscape mode========================= */
/* ================landscape mode========================= */
/* ================landscape mode========================= */
/* ================landscape mode========================= */
/* ================landscape mode========================= */
/* ================landscape mode========================= */
/* ================landscape mode========================= */


@media only screen and (min-width:100px) and (max-width:1080px) and (orientation:landscape){
  .wrapper{
    width:99%;
    font-size:2vw;
    padding:5%;
    border-radius:1px;
    margin-top:0;
    box-shadow:none;
  }
 
  .descarca_sablon{
    font-size:2vw;
    padding:1vw 2vw ;
    border-radius:1px;
  }

  .underline_container{
    display:none;
  }
  .underline_recenzare{
    display:none;
  }
  .steluta{
    display:none;
  }
}