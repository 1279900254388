
.display_search_results{
    background:white;
    padding:0;
    margin:0;
    padding-top:30px;
    padding-bottom:5%;
    border: 1px solid #f1f1f1;
    overflow:visible;
    overflow-wrap:normal;
    margin-left:4%;
    width:80%;
}
.element_curent_div{
    margin-bottom:20px;
    width:100%;
}
.element_curent_div_gri{
    background:#fbfbfb;
    margin-bottom:10px;
    width:100%;
}
.element_curent{
    font-size:18px;
    padding:2px;
    padding-left:10px;

}
.element_curent_titlu{
    font-style:italic;
}
.element_curent_div:hover{
    color:#1dbc60;
}

.element_curent_div_gri:hover span{
    color:#1dbc60 !important;
    cursor:pointer;
}
.element_curent_div:hover span{
    color:#1dbc60 !important;
    cursor:pointer
}
.mergi_inapoi{
    background:#1dbc60;
    color:white;
    font-weight:bold;
    border-radius:5px;
    padding:5px 8px;
    font-size:20px;
    cursor:pointer;
    border:1px solid  #1dbc60;
    outline:none;
    display:inline-block;
    /* margin-right:0; */
    height:0%;
}

.mergi_inapoi:hover{
    color:#1dbc60;
    background:white;
}


.titlu_rezultate_cautare {
    font-size:20px;
    font-weight:bold;
    display:inline-block;
    background:#fbfbfb;
    padding:5px 8px;
    border-radius:5px;
}
.pagination_search_container{
    margin-left:auto;
}


.col_search{
    padding:0;
    margin:0;
    width:90%;
}



.logo_and_title_search{
    width:20%;
    margin:auto;
    display:flex;
    flex-direction:row;
    margin-top:30px;
}
.separator_search{
    margin:auto;
}

.underline_search{
}

.sageti_search{
    display:none;
    position:absolute;
    top:0;
}
.left_search{
    /* height:500px; */
    height:auto !important;
}
.pagination_search_container ul{
    height:auto;
    min-height:50px;
}

.titlu_rezultate{
    margin-left: 50px;
    background:#f1f1f1;
    display:inline-block;
    padding:2px 6px;
}

