.underline_recenzare{
    width:30%;
    display:inline-block;
    text-align:center;
    vertical-align:middle;
    border-bottom:2px solid #1dbc60;
    margin-left:2%;
    margin-right:2%;
    
}
.underline_container_recenzare{
    display:flex;
    /* flex-direction:column; */
    width: 35vw;
    margin:auto !important;
    text-align:center;
    margin-bottom: 2vw;
}

.separator_recenzare{
    margin-left:10px;
}
.procesul{
    margin-bottom:-10px;
}
.separator span{
    vertical-align:middle;
    /* border:1px solid red; */
}
.myicon{
    text-align:center;
    margin-right:16px;
}


/* luat de la epontica*/
.epontica_links_container{
    display:flex;
    margin:0 auto;
    /* width:40%; */
    /* margin-left:31.6vw; */
    
    margin-bottom: 20px;
}

.paragraf_epontica{
    /* width:70vw; */
    margin:auto;
    font-size:16px;
    text-align:justify;
}

@media only screen and (min-width:150px) and (max-width:767px){
    .underline_container_recenzare{
        display:flex;
        flex-direction:row;
        width: 97%;
        margin:auto !important;
        text-align:center;
        margin-bottom: 2vw;
    }
    .procesul{
        display: flex;
        /* text-align: center; */
    }
    .separator_recenzare{
        width: 96%;
    }
   .underline_recenzare{
       width: 80%;
   }
}
